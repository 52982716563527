import { connect } from "react-redux"
import { FC, useEffect, useState } from "react"

import { absolutePath } from "app/helpers/relativePath"
import { ADD_EVENING_REPORTING, ADD_MORNING_PLANNER, ADD_USER, ASSIGNED_TASK_LIST, CHECKING_TASK_LIST, EDIT_MORNING_PLANNER, FOLLOWUP_TASK_LIST, HOME_PAGE, USER_LIST } from "app/helpers/routes"
import { emptyObject, sortedArrayByName } from "app/helpers/helperFunction"
import { getUserList, getUsersByReportingAuthorityList } from "app/reducers/user/userAction"
import { KTCard } from "_metronic/helpers"
import { PageLink, PageTitle } from "_metronic/layout/core"
import { searchParams } from "app/helpers/commonInterface"
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent"
import UsersListToolbar from "app/components/commonComponent/table/UsersListToolbar"
import { editEveningReporting, editMorningPlanner, getDailyMorningPlanner, getEveningReporting, getMorningPlanner, getUsersEveningReporting, getUsersMorningPlanner, sentEveningReportingForDailyMorningPlanner, viewMorningAction } from "app/reducers/morningPlanner/plannerAction"
import MorningPlannerTable from "app/components/pages/morningPlanner/component/MorningPlannerTable"
import EveningPlannerTable from "app/components/pages/morningPlanner/component/EveningPlannerTable"
import AllMorningPlannerTable from "app/components/pages/morningPlanner/component/AllMorningPlannerTable"
import AllEveningReportingTable from "app/components/pages/morningPlanner/component/AllEveningReportingTable"
import AddMorningPlanner from "app/components/pages/morningPlanner/AddMorningPlanner"
import { Search } from "_metronic/partials"
import DropDown from "app/components/commonComponent/dropDown/DropDown"
import AddEveningReporting from "app/components/pages/morningPlanner/AddEveningReporting"
import SingleAccrdian from "app/components/commonComponent/SingleAccordian"
import SingleAccordion from "app/components/commonComponent/SingleAccordian"
import ValidationPopUp from "app/components/pages/morningPlanner/component/ValidationPopUp"

interface props {
    getUserList: Function,
    morningPlannerList: any,
    eveningReportingList: any,
    usersMorningPlannerList: any,
    usersByReportingAuthorityList: any,
    getMorningPlannerDispatch: Function,
    getEveningReportingDispatch: Function,
    getUsersMorningPlannerDispatch: Function,
    getUsersEveningReportingDispatch: Function,
    getUsersByReportingAuthorityListDispatch: Function,
    editMorningPlannerDispatch: Function,
    editEveningReportingDispatch: Function,
    usersEveningReportingList: any,
    editMorningPlanner: any,
    editEveningReporting: any,
    viewMorningAction: any,
    viewEveningAction:any,
    sentEveningReportingForDailyMorningPlannerDispatch:Function,
    dailyMorningPlannerList:any
}

export const userListPage = {
    title: 'Morning Planner',
    path: absolutePath(ADD_MORNING_PLANNER),
    isSeparator: true,
    isActive: true,
}


const MorningPlanner: FC<props> = ({
    morningPlannerList,
    eveningReportingList,
    usersMorningPlannerList,
    usersByReportingAuthorityList,
    getMorningPlannerDispatch,
    getEveningReportingDispatch,
    getUsersMorningPlannerDispatch,
    getUsersEveningReportingDispatch,
    getUsersByReportingAuthorityListDispatch,
    editMorningPlannerDispatch,
    editEveningReportingDispatch,
    usersEveningReportingList,
    editMorningPlanner,
    editEveningReporting,
    viewMorningAction,
    viewEveningAction,
    sentEveningReportingForDailyMorningPlannerDispatch,
    dailyMorningPlannerList
}) => {

    const [morningAuthorityUser, setMorningAuthorityUser] = useState<any[]>();
    const [currentMPUser, setCurrentMPUser] = useState({ value: '', name: '' })
    const [currentERUser, setCurrentERUser] = useState({ value: '', name: '' });
    const [validatePopUpdata,setValidatePopUpdata]=useState<any>({title:null,bodyData:[],showPopUp:false,submitFunc:null,submitBtnName:'Submit',color:'primary'});
 

    const [isEdit, setIsEdit] = useState<number>();

    useEffect(() => {
        if (editMorningPlanner && !editMorningPlanner.loading) {
            if (isEdit == 1) {
                getMorningPlannerDispatch();
            }
            if (isEdit == 3) {
                getUsersMorningPlannerDispatch({ userId: currentMPUser.value })
            }
        }
    }, [editMorningPlanner])

    useEffect(() => {
        if (editEveningReporting && !editEveningReporting.loading) {
            if (isEdit == 2) {
                getEveningReportingDispatch();
            }
            if (isEdit == 4) {
                getUsersEveningReportingDispatch({ userId: currentERUser.value })
            }
        }
    }, [editEveningReporting])

    useEffect(() => {
        let searchObj;
        getUsersByReportingAuthorityListDispatch(searchObj, true);
    }, [getUsersByReportingAuthorityListDispatch]);
    
    useEffect(() => {
        if (usersByReportingAuthorityList.usersByReportingAuthorityList && usersByReportingAuthorityList.usersByReportingAuthorityList.data) {
            setMorningAuthorityUser(usersByReportingAuthorityList.usersByReportingAuthorityList.data.records
                .filter((user: any) => user.IsActive === true)
                .map((user: any) => ({ value: user.Id, name: user.FirstName })));
        }
    }, [usersByReportingAuthorityList]);
    
    useEffect(() => {
        if (morningAuthorityUser && morningAuthorityUser.length > 0) {
            const sortedUsers = sortedArrayByName(morningAuthorityUser);
            setCurrentMPUser(sortedUsers[0]);
            setCurrentERUser(sortedUsers[0]);
        }
    }, [morningAuthorityUser]);
    
    useEffect(() => {
        getMorningPlannerDispatch();
        getEveningReportingDispatch();
        
        
    }, [getMorningPlannerDispatch, getEveningReportingDispatch ]);
  
    useEffect(() => {
       
        if (!usersByReportingAuthorityList.loading && usersByReportingAuthorityList.usersByReportingAuthorityList && usersByReportingAuthorityList.usersByReportingAuthorityList.data && usersByReportingAuthorityList.usersByReportingAuthorityList.data.records.length > 0) {
            var userId = usersByReportingAuthorityList.usersByReportingAuthorityList.data.records
            .filter((user: any) => user.IsActive === true)
            .sort((a: any, b: any) => a.FirstName.localeCompare(b.FirstName))
            .map((user: any) => ({ value: user.Id, name: user.FirstName }))[0]?.value;
        
        //   console.log(usersByReportingAuthorityList)
          if(userId){
            // console.log(userId)
            getUsersMorningPlannerDispatch({ userId });
            getUsersEveningReportingDispatch({ userId });
          }
        }
    }, [getUsersMorningPlannerDispatch,getUsersEveningReportingDispatch,usersByReportingAuthorityList]);

    const sendForApproveMP = () => {
        var data = morningPlannerList.morningPlannerList.data.records.map((planner: any) => (
            {
                ...planner,
                status: 4
            })
        )
        setIsEdit(1)
        editMorningPlannerDispatch(data)
        //console.log(data)
    }
   
    const submitEveningReporting = (data:any,userId:string|null)=>{
         setValidatePopUpdata({title:null,bodyData:[],showPopUp:false,submitFunc:null})
         editEveningReportingDispatch(data)
    }

    const validate =(userId:string | null,dailyMorningPlannerData : any,data : any)=>{
     

        let inValidData:any[] = [];
        let validation = true;
        let valid =true;
        let submitBtn = userId ? 'Approve' : 'Send For Approve';
        let color = userId ? 'success' : 'primary';

       dailyMorningPlannerData.data.records.forEach((planner:any) => {
          valid = planner.TaskTypeId == 1 ? data.find((x:any)=> x.TaskId == planner.TaskId)!== undefined : data.find((x:any)=> x.ActivityId == planner.ActivityId)!== undefined;  
          
          if(!valid){
            inValidData.push(planner)
            validation = false;
          }
        
       });

       if(!validation){
        setValidatePopUpdata({title:"Conformation",bodyData:inValidData,showPopUp:true,submitFunc:()=>submitEveningReporting(data,userId),submitBtnName:submitBtn,color:color})
       }
      
       else{
        editEveningReportingDispatch(data)
       }
    }


    const sendForApproveER =  () => {
        var data = eveningReportingList.eveningReportingList.data.records.map((planner: any) => (
            {
                ...planner,
                status: 4
            })
        )
        setIsEdit(2)
        
         sentEveningReportingForDailyMorningPlannerDispatch(null,validate,data)
         
        // editEveningReportingDispatch(data)
        
    }


    const approveMP = () => {
        if (usersMorningPlannerList && usersMorningPlannerList.usersMorningPlannerList.data) {
            var data = usersMorningPlannerList.usersMorningPlannerList.data.records.map((planner: any) => (
                {
                    ...planner,
                    status: 7
                })
            )
            setIsEdit(3);
            editMorningPlannerDispatch(data)
            //console.log(data)
        }
    }

    const approveER = () => {
        if (usersEveningReportingList && usersEveningReportingList.usersEveningReportingList.data) {
            var data = usersEveningReportingList.usersEveningReportingList.data.records.map((planner: any) => (
                {
                    ...planner,
                    status: 7
                })
            )
            setIsEdit(4)
            sentEveningReportingForDailyMorningPlannerDispatch(currentERUser.value,validate,data)

            // editEveningReportingDispatch(data)
            //console.log(data)
        }
    }
    
    return (
        <>
            <SingleAccordion
                id="accordion1"
                header="Morning Planner"
                body="This is the first item's accordion body."
                data={
                    <>
                        {(viewMorningAction && viewMorningAction.viewMorningAction) && (
                            <div className='d-flex justify-content-between align-items-center flex-wrap gap-10 mb-4'>
                                {/* <div className="fs-2 ">Morning Planner</div> */}<div></div>
                                <AddMorningPlanner ButtonTitle="Add Task" ModalTitle="Add Morning Planner" />
                            </div>
                        )}
                        <div className="mb-6">
                            <KTCard>
                                <MorningPlannerTable />
                            </KTCard>
                        </div>
                        <button className="btn btn-primary btn-sm"
                            disabled={editMorningPlanner.loading || viewMorningAction.viewMorningAction == false}
                            onClick={sendForApproveMP}>
                            {(editMorningPlanner && editMorningPlanner.loading) ? (
                                <>Sending... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                            ) : (
                                <> Send For Approval</>
                            )}
                        </button>
                    </>}
            />

            <SingleAccordion
                id="accordion2"
                header="Evening Reporting"
                body="This is the first item's accordion body."
                data={
                    <>
                     {(viewEveningAction && viewEveningAction.viewEveningAction) && (
                        <div className='d-flex justify-content-between align-items-center flex-wrap gap-10 mb-4'>
                            <div></div>
                            <AddEveningReporting ButtonTitle="Add Task" ModalTitle="Add Evening Reporting" />
                        </div>
                     )}
                        <div className="mb-6">
                            <KTCard>
                                <EveningPlannerTable />
                            </KTCard>
                        </div>
                        <button className="btn  btn-primary btn-sm"
                         disabled={editEveningReporting.loading || viewEveningAction.viewEveningAction == false || (dailyMorningPlannerList && dailyMorningPlannerList.sendLoading)} 
                         onClick={sendForApproveER}>
                            {((editEveningReporting && editEveningReporting.loading) || (dailyMorningPlannerList && dailyMorningPlannerList.sendLoading)) ? (
                                <>Sending... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                            ) : (
                                <> Send For Approval</>
                            )}
                        </button>
                    </>}
            />

            {(morningAuthorityUser && morningAuthorityUser.length > 0) && (
                <SingleAccordion
                    id="accordion3"
                    header="Morning Reported Planner"
                    body="This is the first item's accordion body."
                    data={
                        <>
                            <div className='d-flex justify-content-start align-items-center justify-content-between flex-wrap gap-3 mb-4 fs-2'>
                                <div></div>
                                <div className="d-flex justify-content-start align-items-center gap-10">
                                    <div className="w-150px">
                                        <DropDown
                                            className="text-start form-control form-control-sm form-control-solid form-select "
                                            placeholder="Select user"
                                            options={morningAuthorityUser}
                                            name="ReportedUser"
                                            currentValue={currentMPUser}
                                            apiCallDispatch={(e: any) => {
                                                var id = e.target.getAttribute('data-id');
                                                const data = morningAuthorityUser.filter((user: any) => user.value == id);
                                                setCurrentMPUser({ value: data[0].value, name: data[0].name })
                                                getUsersMorningPlannerDispatch({ userId: id })
                                            }
                                            }
                                        />
                                    </div>
                                    <AddMorningPlanner ButtonTitle="Add User Task" ModalTitle="Add Reported Morning Planner" userId={currentMPUser.value} />
                                </div>
                            </div>
                            <div className="mb-6">
                                <KTCard>
                                    <AllMorningPlannerTable />
                                </KTCard>
                            </div>
                            <button className="btn btn-success btn-sm" disabled={editMorningPlanner.loading} onClick={approveMP}>
                                {(editMorningPlanner && editMorningPlanner.loading) ? (
                                    <>Approving... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                                ) : (
                                    <> Approve</>
                                )}
                            </button>
                        </>}
                />
            )}

            {(morningAuthorityUser && morningAuthorityUser.length > 0) && (
                <SingleAccordion
                    id="accordion4"
                    header=" Evening Reported Reporting"
                    body="This is the first item's accordion body."
                    data={
                        <>
                            <div className='d-flex justify-content-start align-items-center justify-content-between flex-wrap gap-3 mb-4 fs-2'>
                                <div></div>
                                <div className="d-flex justify-content-start align-items-center gap-10">
                                    <div className="w-150px">
                                        <DropDown
                                            className="text-start form-control form-control-sm form-control-solid form-select "
                                            placeholder="Select user"
                                            options={morningAuthorityUser}
                                            name="ReportedUser1"
                                            currentValue={currentERUser}
                                            apiCallDispatch={(e: any) => {
                                                var id = e.target.getAttribute('data-id');
                                                const data = morningAuthorityUser.filter((user: any) => user.value == id);
                                                setCurrentERUser({ value: data[0].value, name: data[0].name })
                                                getUsersEveningReportingDispatch({ userId: id })
                                            }
                                            }
                                        />
                                    </div>
                                    <AddEveningReporting ButtonTitle="Add User Task" ModalTitle="Add Reported Morning Planner" userId={currentERUser.value} />
                                </div>
                            </div>
                            <div className="mb-6">
                                <KTCard>
                                    <AllEveningReportingTable />
                                </KTCard>
                            </div>
                            <button className="btn btn-success btn-sm" disabled={editEveningReporting.loading || (dailyMorningPlannerList && dailyMorningPlannerList.sendLoading)} onClick={approveER}>
                                {((editEveningReporting && editEveningReporting.loading) || (dailyMorningPlannerList && dailyMorningPlannerList.sendLoading)) ? (
                                    <>Approving... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></>
                                ) : (
                                    <> Approve</>
                                )}
                            </button>
                        </>}
                />
            )}

        {validatePopUpdata.showPopUp && (
           <ValidationPopUp 
           validatePopUpdata={validatePopUpdata} 
           setValidatePopUpdata={setValidatePopUpdata} 
         />
        )}
          
        </>
    )
}

const mapStateToProps = (state: any) => {
    // console.log(state,state.dailyMorningPlannerList)
    return {
        morningPlannerList: state.morningPlannerList,
        eveningReportingList: state.eveningReportingList,
        usersMorningPlannerList: state.usersMorningPlannerList,
        usersEveningReportingList: state.usersEveningReportingList,
        usersByReportingAuthorityList: state.usersByReportingAuthorityList,
        editMorningPlanner: state.editMorningPlanner,
        editEveningReporting: state.editEveningReporting,
        viewMorningAction: state.viewMorningAction,
        viewEveningAction:state.viewEveningAction,
        dailyMorningPlannerList:state.dailyMorningPlannerList
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        getUserList: (searchObj: searchParams) => dispatch(getUserList(searchObj)),
        getMorningPlannerDispatch: () =>
            dispatch(getMorningPlanner()),
        getEveningReportingDispatch: () =>
            dispatch(getEveningReporting()),
        getUsersMorningPlannerDispatch: (searchObj: searchParams) =>
            dispatch(getUsersMorningPlanner(searchObj)),
        getUsersEveningReportingDispatch: (searchObj: searchParams) =>
            dispatch(getUsersEveningReporting(searchObj)),
        getUsersByReportingAuthorityListDispatch: (searchObj: searchParams, isActive: boolean) =>
            dispatch(getUsersByReportingAuthorityList(searchObj, isActive)),
        editMorningPlannerDispatch: (data: any) => dispatch(editMorningPlanner(data)),
        editEveningReportingDispatch: (data: any) => dispatch(editEveningReporting(data)),

        sentEveningReportingForDailyMorningPlannerDispatch: (userId:string | null,callback:Function,data:any) => dispatch(sentEveningReportingForDailyMorningPlanner(userId,callback,data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MorningPlanner)
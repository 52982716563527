import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { SmallLoader } from "../buttons/Buttons";
import { sortedArrayByName } from "app/helpers/helperFunction";

interface subDropDown {
  value: string | number;
  name: string;
}

interface option {
  value: string | number | undefined;
  name: string | undefined;
  subDropDown?: Array<subDropDown>;
}

interface props {
  placeholder: string;
  options: Array<option>;
  setFieldValue?: Function;
  name?: string;
  apiCallDispatch?: Function;
  defaultValue?: option;
  className: string;
  currentValue?: {
    value: string | number | undefined;
    name?: string | undefined;
  };
  disabled?: boolean;
  showSearch?: boolean;
  displayLoader?: boolean;
  setFieldValueDefault?: boolean;
  prefix?: string;
}

interface selected {
  selectedValue: number | string | null | undefined;
  selectedName: string | null | undefined;
}

const DropDown: FC<props> = ({
  disabled,
  currentValue,
  apiCallDispatch,
  placeholder,
  options,
  setFieldValue,
  name,
  defaultValue,
  className,
  showSearch,
  displayLoader,
  setFieldValueDefault,
  prefix
}) => {
  const [selected, setSelected] = useState<selected>({
    selectedValue: defaultValue?.value || currentValue?.value,
    selectedName: defaultValue?.name || currentValue?.name,
  });
  const [search, setSearch] = useState("");

  const handleOptionClick = (optionValue: string | number | undefined | null, optionName: string | null | undefined) => {
    setSelected({
      selectedValue: optionValue,
      selectedName: optionName,
    });
    setFieldValue && setFieldValue(name, optionValue);
    apiCallDispatch && apiCallDispatch(optionValue);
  };

  useEffect(() => {
    if (setFieldValueDefault) {
      setFieldValue && setFieldValue(name, currentValue?.value);
    }
  }, []);


  // const handleOptionClick = (e: any) => {
  //   const listItem = (e.target as HTMLElement).closest("li");
  //   if (listItem && listItem.dataset.id) {
  //     setSelected({
  //       selectedName: listItem.textContent,
  //       selectedValue: listItem.dataset.id,
  //     });
  //     setFieldValue && setFieldValue(name, listItem.dataset.id);
  //     apiCallDispatch && apiCallDispatch(listItem.dataset.id);
  //   }
  // };

  const searchFun = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    let input = document.getElementById(`myInput-${name}`) as HTMLInputElement;
    let filter = input.value.toUpperCase();
    let div = document.getElementById(`custom-dropdown-${name}`) as HTMLElement;
    let a = div.getElementsByTagName("li") as HTMLCollectionOf<HTMLLIElement>;
    for (let i = 0; i < a.length; i++) {
      let txtValue = a[i].textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        a[i].classList.remove("hidden-imp");
      } else {
        a[i].classList.add("hidden-imp");
      }
    }
  };

  return (
    <div className="btn-group w-100">
      <button
        className={className}
        type="button"
        id="dropdownMenuClickableInside"
        data-bs-toggle="dropdown"
        // data-bs-auto-close="outside"
        aria-expanded="false"
        aria-haspopup="true"
        disabled={disabled}
      >
        {prefix}{" "}
        {(currentValue
          ? currentValue.value
            ? currentValue.name || selected.selectedName
            : ""
          : selected.selectedName) || placeholder}
        {displayLoader && (
          <div className="fs-7">
            <SmallLoader />
          </div>
        )}
      </button>
      <ul
        id={`custom-dropdown-${name}`}
        className="dropdown-menu w-100"
        aria-labelledby="dropdownMenuClickableInside"
        onClick={(e) => {
          if ((e.target as HTMLLIElement).dataset.id) {
            setSelected({
              selectedName: (e.target as HTMLLIElement).textContent,
              selectedValue: (e.target as HTMLLIElement).dataset.id,
            });
            setFieldValue &&
              setFieldValue(name, (e.target as HTMLLIElement).dataset.id);
            apiCallDispatch && apiCallDispatch(e);
          }
        }}
      >
        {showSearch && (
          <div className="px-5 py-3">
            <input
              type="text"
              className="w-100 dropdown-search form-control"
              onChange={searchFun}
              value={search}
              placeholder="Search Here"
              id={`myInput-${name}`}
            />
          </div>
        )}
        <div className="mh-150px overflow-auto vertical-scroll">
          {options?.map((option: option, index: number) => {
            if (option.subDropDown) {
              return (
                <li key={index} className="p-3">
                  <span
                    className="dropdown-item dropdown-toggle ps-0"
                    id="dropdownMenuClickableInside1"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    {option.name}
                  </span>
                  <ul
                    className="dropdown-menu w-75"
                    aria-labelledby="dropdownMenuClickableInside1"
                  >
                    {option.subDropDown.map(
                      (subDropDownItem: subDropDown, index: number) => (
                        <li
                          key={index}
                          className={`dropdown-item position-relative cursor-pointer
                            ${
                              (
                                currentValue
                                  ? currentValue.value == subDropDownItem.value
                                  : selected.selectedValue ==
                                    subDropDownItem.value
                              )
                                ? "dropdown-option-selected"
                                : ""
                            }`}
                          data-id={subDropDownItem.value}
                        >
                          {subDropDownItem.name}
                        </li>
                      )
                    )}
                  </ul>
                </li>
              );
            } else {
              return (
                <li
                  key={index}
                  className={`dropdown-item d-flex gap-2 position-relative cursor-pointer
                        ${
                          (
                            currentValue
                              ? currentValue.value == option.value
                              : selected.selectedValue == option.value
                          )
                            ? "dropdown-option-selected"
                            : ""
                        }`}
                  data-id={option.value}
                  onClick={() => handleOptionClick(option.value, option.name)}
                  //onClick={(e) => handleOptionClick(e)}
                >
                  {option.name}
                </li>
              );
            }
          })}
        </div>
      </ul>
    </div>
  );
};

export default DropDown;


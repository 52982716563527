import { PageLink, PageTitle } from "_metronic/layout/core";
import { absolutePath } from "app/helpers/relativePath";
import { FC, useEffect } from "react";
import { connect } from "react-redux";
import { adminHomePage } from "../main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";
import UsersListSearchComponent from "app/components/commonComponent/table/UsersListSearchComponent";
import { useGetSearchObject } from "app/helpers/customHooks";
import Table from "app/components/commonComponent/table/Table";
import TaskReportTableRow from "./component/TaskReportTableRow";
import { getTaskReportList } from "app/reducers/task/taskAction";
import { searchParams } from "app/helpers/commonInterface";
import { taskReportListState } from "app/reducers/task/getTaskReportListReducer";
import { TASK_REPORT_LIST } from "app/helpers/routes";
import { KTCard } from "_metronic/helpers";
import TaskFilter from "./component/TaskFilter";
import hasPaymentAccess from "../auth/core/paymentAccess";
import hasPermission, { actionsRole } from "../auth/core/permission";

interface props {
  taskReportDetails: taskReportListState;
  getTaskReportListDispatch: Function;
}

export const taskReportListPage = {
  title: "Task Report",
  path: absolutePath(TASK_REPORT_LIST),
  isSeparator: true,
  isActive: true,
};

const accountBreadCrumbs: Array<PageLink> = [adminHomePage, emptyObject];

const TaskReportList: FC<props> = ({
  getTaskReportListDispatch,
  taskReportDetails,
}) => {
  
const HEADERS = [
  {
    columnName: "Task Code",
    id: "TaskCode",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Client Name",
    id: "ClientName",
    className: "min-w-125px",
  },
  {
    columnName: "Task",
    id: "TypeName",
    className: "min-w-125px",
  },
  {
    columnName: "Start Date",
    id: "StartDate",
    className: "min-w-125px",
  },
  {
    columnName: "Due Date",
    id: "DueDate",
    className: "min-w-125px",
  },
  {
    columnName: "Article",
    id: "PersonAssigned",
    className: "min-w-125px",
  },
  {
    columnName: "Managed By",
    id: "ManagerName",
    className: "min-w-125px",
  },
  {
    columnName: "Task Type",
    id: "IsRepeatative",
    className: "min-w-125px",
  },
  ...((hasPaymentAccess())
      ? [
          {
            columnName: "Billing Status",
            id: "BillingStatus",
            className: "min-w-125px",
          },
        ]
      : []),
  {
    columnName: "Work Status",
    id: "StatusValue",
    className: "min-w-125px",
  },
];

  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getTaskReportListDispatch(searchObj);
  }, [searchObj]);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Tasks</PageTitle>
      <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 mb-4 position-relative">
        <UsersListSearchComponent />
        <TaskFilter />
      </div>
      <KTCard>
        <Table
          checkBoxColumn={{ show: false }}
          error={taskReportDetails.error}
          headers={HEADERS}
          success={taskReportDetails.taskDetails?.success}
          loading={taskReportDetails.loading}
          paginate={{
            show: true,
            pagination: taskReportDetails.taskDetails?.pagination,
            PageNumber: Number(searchObj.PageNumber || 1),
            PageSize: Number(searchObj.PageSize) || 10,
          }}
          tableData={taskReportDetails.taskDetails?.data?.records}
          tableRow={(row: any, index: number) => (
            <TaskReportTableRow row={row} key={`row-${index}-${row.id}`} />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    taskReportDetails: state.taskReportDetails,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getTaskReportListDispatch: (searchObj: searchParams) =>
      dispatch(getTaskReportList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskReportList);

import { connect } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC,  useState } from "react";
import { object, string } from "yup";

import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { addFileObject } from "app/helpers/commonInterface";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import { getFormData, getImage } from "app/helpers/helperFunction";
import { generalFileUpload, getTask } from "app/reducers/task/taskAction";
import { useAuth } from "app/components/pages/auth";
import { Modal } from "react-bootstrap";
import {
  ResetButton,
  SmallLoader,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { useParams } from "react-router-dom";

interface taskDetailsData {
  ManagerId: string;
  ManagerName: string;
  ExpertName: string;
  ExpertId: string;
}
interface props {
  closeModal: Function;
  sentTaskForCheckingDispatch: Function;
  getTaskData: Function;
  uploadFileDispatch: Function;
  taskDetails: taskDetailsData;
  assigneeId: string;
  showPopup: boolean;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

interface sentMessageData {
  reviewerId: string;
  reviewMessage: string;
  reviewFilePath: string;
  fileObject: any;
}


const initialValue: sentMessageData = {
  reviewerId: "",
  reviewMessage: "",
  reviewFilePath: "",
  fileObject: {},
};

const SentForCheckPopup: FC<props> = ({
  masterDataList,
  showPopup,
  taskDetails,
  closeModal,
  sentTaskForCheckingDispatch,
  uploadFileDispatch,
  assigneeId,
  getTaskData
}) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const sentMessageValidateSchema = object().shape({
    reviewerId: string().test("check", "Reviewer is required", (value) => {
      if (!value && currentUser?.Id === assigneeId) return false;
      return true;
    }),
    reviewMessage: string().test({
      name: "required-without-file",
      test: function (value) {
        const file: any = this.parent.reviewFilePath;
        return (value && !file) || (!value && file) || (value && file);
      },
      message: "Message or File is required",
    }),
    reviewFilePath: string().test({
      name: "required-without-file",
      test: function (value) {
        const message = this.parent.reviewMessage;
        return (value && !message) || (!value && message) || (value && message);
      },
      message: "Message or File is required",
    }),
  });

  const onChangeFileHandler = (file: File, setValues: any, values: any) => {
    setLoading(true);
    uploadFileDispatch(
      getFormData({ file: file }),
      (fileObject: addFileObject) => {
        setValues({
          ...values,
          reviewFilePath: fileObject.FilePath,
          fileObject: fileObject,
        });
        setLoading(false);
      }
    );
  };

  return (
    <Modal
      show={showPopup}
      aria-labelledby="contained-modal-session"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={sentMessageValidateSchema}
          onSubmit={async (values) => {
            await sentTaskForCheckingDispatch(values);
            closeModal();
            getTaskData(params.id);
          }}
        >
          {({
            touched,
            errors,
            isSubmitting,
            setFieldValue,
            values,
            setValues,
          }) => {
            return (
              <Form noValidate className="form">
                <div className="">
                  <div className="mb-4">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6 pt-0">
                      Message
                    </label>
                    <div className="">
                      <Field
                        as="textarea"
                        className={`form-control form-control-lg form-control-solid
                        ${
                          touched.reviewMessage &&
                          errors.reviewMessage &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="Message"
                        name="reviewMessage"
                      />
                      <ErrorMessage
                        name="reviewMessage"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                  </div>
                  <div className="mb-6">
                    {!values.reviewFilePath && (
                      <label htmlFor="reviewFilePath" className="col-lg-8">
                        <div className="d-inline-flex gap-3 btn btn-light-primary btn-active-light-primary btn-sm">
                          {loading ? (
                            <SmallLoader />
                          ) : (
                            <i className="bi bi-upload me-1"></i>
                          )}
                          Upload File
                        </div>
                        <Field
                          type="file"
                          value={undefined}
                          className="d-none"
                          id="reviewFilePath"
                          name="reviewFilePath"
                          onChange={(e: any) =>
                            onChangeFileHandler(
                              e.target.files[0],
                              setValues,
                              values
                            )
                          }
                          accept=".pdf"
                        />
                        <ErrorMessage
                          name="reviewFilePath"
                          component="div"
                          className="errorMsg"
                        />
                      </label>
                    )}
                    {values.reviewFilePath && (
                      <div className="col-lg-9 ms-3 upload-file-display d-flex justify-content-between align-items-center gap-2 fw-semibold p-3 rounded-2 shadow-sm text-gray-600">
                        <div className="d-flex gap-3 align-items-center">
                          <div className="w-35px">
                            <ImageDisplay
                              path={getImage(values.fileObject.MimeType)}
                              errorPath="/media/task/doc-1.png"
                              className="img-fit-to-div"
                              altText="documentType"
                            />
                          </div>
                          <div className="fw-semibold">
                            <div className="fs-6 fw-semibold text-dark text-hover-primary upload-file-name">
                              {values.fileObject.FileName}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <button
                            className="btn p-0"
                            type="button"
                            onClick={() =>
                              setValues({
                                ...values,
                                reviewFilePath: "",
                                fileObject: {},
                              })
                            }
                          >
                            <i className="text-hover-primary fa fa-solid fa-trash fs-6"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* //reviewer dropdown */}
                </div>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <div>
                    {currentUser?.Id === assigneeId && (
                      <div className="w-200px">
                        <DropDown
                          className="text-start form-control form-control-sm form-control-solid form-select form-select-sm"
                          placeholder="Select Reviewer"
                          options={
                            masterDataList.masterData?.data?.records?.Admin &&
                            masterDataList.masterData?.data?.records?.Article &&
                            masterDataList.masterData?.data?.records?.TeamLeader
                              ? [
                                  ...masterDataList.masterData?.data?.records?.Admin?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                  ...masterDataList.masterData?.data?.records?.TeamLeader?.map(
                                    (assignee: any) => {
                                      return {
                                        value: assignee.Id,
                                        name: `${assignee.FirstName} ${assignee.LastName}`,
                                      };
                                    }
                                  ),
                                ]
                              : []
                          }
                          setFieldValue={setFieldValue}
                          name="reviewerId"
                          currentValue={{ value: values.reviewerId }}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="reviewerId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    )}
                  </div>
                  <div className="d-flex gap-3">
                    <ResetButton
                      className="btn btn-light btn-sm btn-active-light-primary"
                      name="Cancel"
                      onClickCallback={() => {
                        closeModal();
                      }}
                      disabled={isSubmitting}
                    />
                    <SubmitButton
                      className="btn btn-primary btn-sm"
                      isSubmitting={isSubmitting}
                      name="Submit"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
      
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    uploadFileDispatch: (file: any, successCallback: Function) =>
      dispatch(generalFileUpload(file, successCallback)),
    getTaskData: (taskId: number) => dispatch(getTask(taskId)),
  };
  
};

export default connect(mapStateToProps, mapDispatchToProps)(SentForCheckPopup);

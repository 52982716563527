import { connect } from "react-redux";
import { FC, useEffect } from "react";

import Table from "app/components/commonComponent/table/Table";
import { useGetSearchObject } from "app/helpers/customHooks";
import MPCustomRow from "./MPCustomRow";
import EPCustomRow from "./EPCustomRow";
import { viewEveningAction } from "app/reducers/morningPlanner/plannerAction";

const HEADERS = [

  {
    columnName: "Task Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Client Name",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "StartTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "EndTime",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Duration",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Date",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Discription",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Work Status",
    id: "NULL",
    className: "min-w-125px",
  }
];

interface props {
  eveningReportingList:any,
  viewEveningActionDispatch:Function
}

const EveningPlannerTable: FC<props> = ({eveningReportingList,viewEveningActionDispatch}) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
  }, [searchObj]);

  useEffect(()=>{
    if(eveningReportingList && eveningReportingList.eveningReportingList.data){
       if(eveningReportingList.eveningReportingList.data.records[0]?.Status > 3){
        viewEveningActionDispatch(false);
       }
       else{
        viewEveningActionDispatch(true);
       }
    }
},[eveningReportingList])

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={eveningReportingList.error}
        headers={HEADERS}
        success={eveningReportingList.eveningReportingList?.success}
        loading={eveningReportingList.loading}
        paginate={{
          show: false,
          pagination: eveningReportingList.eveningReportingList?.pagination,
          PageNumber: Number(searchObj.PageNumber) || 1,
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={eveningReportingList.eveningReportingList?.data?.records}
        tableRow={(row: any, index: number) => (
          <EPCustomRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    eveningReportingList: state.eveningReportingList,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    viewEveningActionDispatch :(data:boolean)=>dispatch(viewEveningAction(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EveningPlannerTable);

import { absolutePath } from "../../../../helpers/relativePath";
import AdminTaskListing from "./dashboard/AdminTaskListing";
import DisplayDailyTask from "./dashboard/DisplayDailyTask";
import { HOME_PAGE } from "../../../../helpers/routes";
import { PageTitle } from "../../../../../_metronic/layout/core";
import AssignedActivityList from "./activity/AssignedActivityList";
import NotificationList from "./dashboard/NotificationList";
import MorningPlannerList from "./dashboard/MorningPlannerList";
import RequestList from "./dashboard/RequestList";
import hasPermission, { actionsRole } from "../../auth/core/permission";

export const adminHomePage = {
  title: "Dashboard",
  path: absolutePath(HOME_PAGE),
  isSeparator: false,
  isActive: false,
};

const AdminMain = () => {
  return (
    <div>
      <PageTitle>Dashboard</PageTitle>
      <div className="row">
        <DisplayDailyTask />
        <AssignedActivityList />
        <NotificationList />
        {hasPermission(actionsRole.ADMIN) && <RequestList />}
      </div>
      <AdminTaskListing />
      <MorningPlannerList/>
    </div>
  );
};

export default AdminMain;

import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import {
  getReimbursementInvoice,
  updateReimbursementInvoice,
} from "app/reducers/reimbursement/reimbursementAction";
import { FC, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import EditReimbursementInvoice from "./component/EditReimbursementInvoice";

interface props {
  reimbursementInvoiceDispatch: Function;
  reimbursementInvoiceData: any;
  reimbursementInvoiceLoading: boolean;
  reimbursementInvoiceError: string;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  showInvoice: { show: boolean; billId: number | undefined };
  closeModel: Function;
  updateReimbursementInvoiceDispatch: Function;
}

export interface task {
  ClientId: number;
  ClientName: string;
  Id: number;
  TaskCode: string;
}

export interface reimbursementInvoiceInitialValue {
  FirmId: number;
  FirmName: string;
  Discount: number;
  ClientId: number;
  TasksIds: { value: number; name: string }[];
  ClientName: string;
  ClientIds: { value: number; name: string }[];
  NewTaskIds: { value: number; name: string }[];
  TotalTaxAmount: number;
  TotalAmountWithoutTax: number;
  TotalAmount: number;
  BillNumber: string;
  IsProformaInvoice: boolean;
  Id: number;
  PaymentStatus: number;
}

export const getInitialValue = (reimbursementInvoice: any): reimbursementInvoiceInitialValue => {
  return {
    Id: reimbursementInvoice?.Id,
    FirmId: reimbursementInvoice?.Firm?.Id,
    FirmName: reimbursementInvoice?.Firm?.Name,
    Discount: reimbursementInvoice?.Discount,
    TasksIds:
    reimbursementInvoice?.Tasks.map((task: task) => ({
        value: task.Id,
        name: task.TaskCode,
      })) || [],
    ClientId: reimbursementInvoice?.ClientId,
    ClientName: reimbursementInvoice?.ClientName,
    ClientIds: [],
    NewTaskIds: [],
    TotalTaxAmount: reimbursementInvoice?.TotalTaxAmount,
    TotalAmountWithoutTax: reimbursementInvoice?.TotalAmountWithoutTax,
    TotalAmount: reimbursementInvoice?.TotalAmount,
    BillNumber: reimbursementInvoice?.BillNumber,
    IsProformaInvoice: reimbursementInvoice?.IsProformaInvoice,
    PaymentStatus: reimbursementInvoice?.PaymentStatus,
  };
};

const ReimbursementInvoiceForm: FC<props> = ({
  showInvoice,
  reimbursementInvoiceDispatch,
  reimbursementInvoiceData,
  reimbursementInvoiceLoading,
  reimbursementInvoiceError,
  masterDataList,
  closeModel,
  updateReimbursementInvoiceDispatch,
}) => {
  const params = useParams();

  useEffect(() => {
    if (!params.id && showInvoice.show && showInvoice.billId) {
      reimbursementInvoiceDispatch(showInvoice.billId);
    }
  }, [showInvoice.billId]);

console.log(reimbursementInvoiceData, "RIDDD");
  return (
    <ErrorBoundary>
      <Modal
        show={showInvoice.show}
        aria-labelledby="contained-modal-billing"
        centered
        data-toggle="modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Update Reimbursement</Modal.Title>
        </Modal.Header>
        {reimbursementInvoiceLoading ? (
          <div className="py-5">
            <Loader />
          </div>
        ) : reimbursementInvoiceError ? (
          <ErrorDisplay error={reimbursementInvoiceError} />
        ) : (
          <EditReimbursementInvoice
            initialValue={getInitialValue(reimbursementInvoiceData?.data?.records)}
            masterDataList={masterDataList}
            closeModel={closeModel}
            updateReimbursementInvoiceDispatch={updateReimbursementInvoiceDispatch}
          />
        )}
      </Modal>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {
  return {
    reimbursementInvoiceLoading: state.reimbursementInvoice.loading,
    reimbursementInvoiceData: state.reimbursementInvoice.reimbursementInvoice,
    reimbursementInvoiceError: state.reimbursementInvoice.error,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    reimbursementInvoiceDispatch: (invoiceId: number) =>
      dispatch(getReimbursementInvoice(invoiceId)),
    updateReimbursementInvoiceDispatch: (data: any, successCallback: Function) =>
      dispatch(updateReimbursementInvoice(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReimbursementInvoiceForm);

import { getAuth } from "./AuthHelpers";

export const actionsRole = {
    "MASTER_ADMIN": "Master Admin",
    "ADMIN": "Admin",
    "USER": "User",
    "TEAM_LEAD" : "Team Leader",
    "ARTICLE" : "Article"
};

const mappings = new Map();

mappings.set(actionsRole.MASTER_ADMIN, ["Master Admin"]);
mappings.set(actionsRole.ADMIN, ["Master Admin","Admin"]);
mappings.set(actionsRole.TEAM_LEAD, ["Team Leader","Admin","Master Admin"]);
mappings.set(actionsRole.USER, ["User","Team Leader","Admin","Master Admin"]);
mappings.set(actionsRole.ARTICLE, ["User"]);

function hasPermission(action: string) {
    const auth = getAuth()
    if (auth) {
        if (!auth.Roles) {
            return false;
        }
        if (mappings.has(action) && auth.Roles) {

            return mappings.get(action).filter((value: string) => auth.Roles.indexOf(value) !== -1)?.length > 0;
        }
    }
    return false;
}

export default hasPermission;

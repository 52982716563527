import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { TASK_DETAIL_PAGE_FROM_HOME } from "app/helpers/routes";
import Avatar from "../../../../commonComponent/Avatar";
import { absolutePath } from "../../../../../helpers/relativePath";
import {
  convert,
  getColor,
  getStatus,
} from "../../../../../helpers/helperFunction";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { PAYMENT_STATUS } from "app/helpers/constants";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import PinSVG from "app/components/pages/task/component/PinSVG";
import hasPaymentAccess from "app/components/pages/auth/core/paymentAccess";
import hasPermission, { actionsRole } from "app/components/pages/auth/core/permission";

interface props {
  row: any;
}

const AdminCheckingTableRow: FC<props> = ({ row }) => {
  const navigate = useNavigate();

  const taskDetailHandler = () => {
    navigate(absolutePath(`${TASK_DETAIL_PAGE_FROM_HOME}/${row.Id}`));
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">
        <div className="text-gray-800 fw-bold ps-1">{row.TaskCode}</div>
        {/* {row.IsOverdue && (
          <div className="d-flex align-items-center text-gray-500 fs-7">
            <ImageDisplay
              path="/media/task/overdue_flag.png"
              errorPath="/media/task/overdue_flag.png"
              altText="flag"
              className="w-15px"
            />
            <div className="text-danger w-100px">Overdue</div>
          </div>
        )} */}
      </td> 
      <td className="text-dark fw-bold">{row.ClientName}</td>
      <td>{row.TypeName}{row.IsUrgent && (
          <div className="d-flex align-items-center text-primary-500 fs-7">
           
            <PinSVG fill="#009EF7"/>
            <div className="text-primary w-100px">Urgent</div>
          </div>
        )}</td>
      <td>{row.DueDate ? convert(row.DueDate) : "-"}{row.IsOverdue && (
          <div className="d-flex align-items-center text-gray-500 fs-7">
            <PinSVG fill="#F1416C"/>
            <div className="text-danger w-100px">Overdue</div>
          </div>
        )}</td>
      <td>
        {row.PersonAssigned ? (
          <div className="d-flex">
            <div className="min-w-150px align-items-center bg-light border d-flex fs-7 fw-semibold py-1 px-2 rounded-pill text-gray-600 min-w-125px">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.PersonAssigned}
                  lastName={""}
                  size={20}
                  color={getColor(row.PersonAssigned).color}
                  background={getColor(row.PersonAssigned).background}
                  length={1}
                  rounded={true}
                />
              </div>
              {row.PersonAssigned}
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>
        {row.ManagerName ? (
          <div className="d-flex">
            <div className="min-w-150px d-flex align-items-center bg-light border fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.ManagerName}
                  lastName={""}
                  size={20}
                  color={getColor(row.ManagerName).color}
                  background={getColor(row.ManagerName).background}
                  length={1}
                  rounded={true}
                />
              </div>
              <span className="w-auto">{row.ManagerName}</span>
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>{row.IsRepeatative ? "Repetitive" : "One Time Task"}</td>
      {hasPaymentAccess() && <td>
        <span className={`badge bill-status-${row.BillingStatus}`}>
          {PAYMENT_STATUS[row.BillingStatus]}
        </span>
      </td>}
      <td>
        <span className={`badge status-${row.StatusValue}`}>
          {getStatus(row.StatusValue).name}
        </span>
      </td>
      <td className="pe-5">
        <button
          className={`btn btn-icon btn-bg-light btn-light btn-sm btn-active-light-primary`}
          title="Task Detail"
          onClick={taskDetailHandler}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr064.svg"
            className="svg-icon-2 svg-icon-primary"
          />
        </button>
      </td>
    </tr>
  );
};

export default AdminCheckingTableRow;

import { connect } from "react-redux";
import { FC, useEffect } from "react";

import ExpenseTableRow from "./ExpenseTableRow";
import { getExpenseList } from "app/reducers/expense/expenseAction";
import Table from "app/components/commonComponent/table/Table";
import { searchParams } from "app/helpers/commonInterface";
import { useGetSearchObject } from "app/helpers/customHooks";

interface props {
  expenseDetails: any;
  getExpenseDetailsDispatch: Function;
}

const HEADERS = [
  {
    columnName: "Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Work Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "Actions",
    className: "min-w-125px text-end pe-5",
  },
];

const ExpenseTable: FC<props> = ({
  expenseDetails,
  getExpenseDetailsDispatch,
}) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
    getExpenseDetailsDispatch(searchObj);
  }, [getExpenseDetailsDispatch, searchObj]);

  return (
    <>
      
      <Table
        checkBoxColumn={{ show: false }}
        error={expenseDetails.error}
        headers={HEADERS}
        success={expenseDetails.expenseList?.success}
        loading={expenseDetails.loading}
        paginate={{ show: false }}
        tableData={expenseDetails.expenseList?.data?.records}
        tableRow={(row: any, index: number) => (
          <ExpenseTableRow key={`row-${index}-${row.id}`} row={row} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    expenseDetails: state.expenseList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getExpenseDetailsDispatch: (searchObj: searchParams) =>
      dispatch(getExpenseList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTable);

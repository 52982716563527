import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getCategory } from "../../../../../reducers/category/categoryAction";
import { KTCard } from "../../../../../../_metronic/helpers";
import TypeTableRow from "./TypeTableRow";
import Table from "app/components/commonComponent/table/Table";
import { PageLink, PageSubTitle, PageTitle } from "_metronic/layout/core";
import { categoryListPage } from "../../CategoryList";
import { adminHomePage } from "app/components/pages/main/admin/AdminMain";
import { emptyObject } from "app/helpers/helperFunction";

const HEADERS = [
  {
    columnName: "Type Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Work Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-125px d-flex justify-content-end pe-5",
  },
];

interface props {
  categoryDetails: any;
  getCategoryData: Function;
}

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  categoryListPage,
  emptyObject,
];

const TypeTable: FC<props> = ({ getCategoryData, categoryDetails }) => {
  const params = useParams();

  useEffect(() => {
    getCategoryData(params.id);
  }, [getCategoryData, params.id]);

  return (
    <>
      <PageTitle breadcrumbs={accountBreadCrumbs}>Types</PageTitle>
      <PageSubTitle>{ categoryDetails.category?.data?.records?.Name || ""}</PageSubTitle>

      <KTCard>
       
        <Table
          checkBoxColumn={{ show: false }}
          error={categoryDetails.error}
          headers={HEADERS}
          success={categoryDetails.category?.success}
          loading={categoryDetails.loading}
          paginate={{ show: false }}
          tableData={categoryDetails.category?.data?.records?.TaskTypes}
          tableRow={(row: any, index: number) => (
            <TypeTableRow row={row} key={`row-${index}-${row.id}`} />
          )}
        />
      </KTCard>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    categoryDetails: state.category,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCategoryData: (categoryId: number) => dispatch(getCategory(categoryId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TypeTable);

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { connect } from "react-redux";
import { searchParams } from 'app/helpers/commonInterface';
import { getPlannerActivity } from 'app/reducers/activity/activityAction';
import { getPlannerTask } from 'app/reducers/task/taskAction';
import * as Yup from 'yup';
import CustomDatePicker from 'app/components/commonComponent/datePicker/CustomDatePicker';
import DropDown from 'app/components/commonComponent/dropDown/DropDown';
import { addEveningReporting, getDailyMorningPlanner, getEveningReporting, getUsersEveningReporting } from 'app/reducers/morningPlanner/plannerAction';
import Loader from 'app/components/commonComponent/loader/Loader';

// Define the type for the objects in the array
interface ItemType {
    value: number;
    name: string;
}

// Define the array with the specified type
const TaskType: ItemType[] = [
    { value: 1, name: "Task" },
    { value: 2, name: "Activity" }
];

interface props {
    ButtonTitle: string,
    ModalTitle: string,
    userId?: string,
    plannerActivity: any,
    getPlannerActivityDispatch: Function,
    plannerTask: any,
    getPlannerTaskDispatch: Function,
    addEveningReportingDispatch: Function,
    getUsersEveningReportingDispatch: Function,
    getEveningReportingDispatch: Function,
    addEveningReporting:any,
    getDailyMorningPlannerDispatch:Function,
    dailyMorningPlannerList:any
}
const AddEveningReporting = ({
    ButtonTitle,
    ModalTitle,
    userId,
    plannerActivity,
    getPlannerActivityDispatch,
    plannerTask,
    getPlannerTaskDispatch,
    addEveningReportingDispatch,
    getUsersEveningReportingDispatch,
    getEveningReportingDispatch,
    addEveningReporting,
    dailyMorningPlannerList,
    getDailyMorningPlannerDispatch
}: props
) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        if (userId) {
            getUsersEveningReportingDispatch({ userId: userId });
           
        }
        else {
            getEveningReportingDispatch();
           
        }
        setShow(false);
    }
    const [isLoading, setIsLoading] = useState(false);
    //console.log(isLoading)

    const handleShow = () => {
        if (userId != null) {
            getPlannerActivityDispatch({ Paginate: false, userId: userId })
            getPlannerTaskDispatch({ Paginate: false, listType: 'assigned', userId: userId })
            getDailyMorningPlannerDispatch(userId )
        }
        else {
            getPlannerActivityDispatch({ Paginate: false })
            getPlannerTaskDispatch({ Paginate: false, listType: 'assigned' })
            getDailyMorningPlannerDispatch(null)
        }
        setShow(true)
    };

    const [activities, setActivities] = useState<any[]>([]);
    const [workTask, setWorkTask] = useState<any[]>([]);
    const [dailyMorningPlanner,setDailyMorningPlanner] = useState<any[]>([]);

    useEffect(() => {
        if (plannerActivity.plannerActivity && plannerActivity.plannerActivity.data) {
            //status = 3 (Completed)
            setActivities(plannerActivity.plannerActivity.data.records
                //.filter((activity: any) => activity.Status !== 3)
                .map((activity: any) => {
                    return { value: activity.Id, name: activity.Name };
                })
            )
        }
    }, [plannerActivity])

    useEffect(() => {
        if (plannerTask.plannerTask && plannerTask.plannerTask.data) {
            //status = 5 (Completed)
            setWorkTask(plannerTask.plannerTask.data.records
                .filter((activity: any) => activity.Status !== 5)
                .map((activity: any) => {
                    return { value: activity.Id, name: `${activity.ClientName} - ${activity.TypeName}` };
                }))
        }
    }, [plannerTask])

    useEffect(() => {
        if (dailyMorningPlannerList.dailyMorningPlannerList && dailyMorningPlannerList.dailyMorningPlannerList.data) {
            //status = 5 (Completed)
            setDailyMorningPlanner(dailyMorningPlannerList.dailyMorningPlannerList.data.records
                .map((planner: any) => {
                    return { Id: planner.Id,
                            TaskTypeId : planner.TaskTypeId,
                            TaskTypeName : planner.TasktypeName,
                            TaskId:planner.TaskTypeId == '1' ? planner.TaskId : 'NA',
                            TaskName:planner.TaskTypeId == '1' ? planner.TaskName : '',
                            ClientName:planner.TaskTypeId == '1' ? planner.ClientName : '-',
                            ActivityId:planner.TaskTypeId == '2' ? planner.ActivityId : 'NA',
                            ActivityName:planner.TaskTypeId == '2' ? planner.ActivityName : '',
                            StartTime:planner.StartTime,
                            EndTime : planner.EndTime
                    };
            }))
        }
    }, [dailyMorningPlannerList])

    const validateFun = Yup.object({
        TaskTypeId: Yup.string().required('Task Type is required'),
        TaskId: Yup.string().required('Task is required'),
        ActivityId: Yup.string().required('Activity is required'),
        // endTime: Yup.string().when('startTime', (startTime, schema) => {
        //     return startTime && schema.test({
        //         test: function (endTime: any) {
        //             return endTime > startTime;
        //         },
        //         message: 'End Time must be greater than Start Time',
        //     });
        // }),
        endTime: Yup.string().when("startTime", (startTime, schema) => {
            return (
              startTime &&
              schema.test({
                name: "endTimeGreaterThanStartTime",
                test: function (endTime: string) {
                  const parseTimeAMPM = (time: string) => {
                    const [timePart, period] = time.split(" "); 
                    let [hours, minutes, seconds] = timePart.split(":").map(Number);
          
                    if (period === "PM" && hours !== 12) {
                      hours += 12; 
                    } else if (period === "AM" && hours === 12) {
                      hours = 0; 
                    }
          
                    return hours * 3600 + minutes * 60 + (seconds || 0); 
                  };
          
                  try {
                    const startInSeconds = parseTimeAMPM(startTime);
                    const endInSeconds = parseTimeAMPM(endTime);
          
                    return endInSeconds > startInSeconds;
                  } catch (error) {
                    console.error("Error parsing time:", error);
                    return false;
                  }
                },
                message: "End Time must be greater than Start Time",
              })
            );
          }),
    });

    const initialValues = {
        TaskTypeId: '', // default value for TaskTypeId
        TaskId: '', // default value for TaskId
        ActivityId: '', // default value for ActivityId
        startTime: '00:00',
        endTime: '00:00',
        statusId: 3 // only added
    };

    // const convertToTimeOnly = (timeString: any) => {
    //     // Assuming the backend expects a format like 'HH:mm:ss'
    //     const [hours, minutes] = timeString.split(':');
    //     return `${hours}:${minutes}:00`;
    // };
    const convertToTimeOnly = (timeString: string) => {
        // Assuming timeString is in 'h:mm:ss AM/PM' format
        let [timePart, period] = timeString.split(' '); // Split time and period (AM/PM)
        let [hours, minutes] = timePart.split(':').map(Number);
      
        if (period === 'PM' && hours !== 12) {
          hours += 12; // Convert PM hours to 24-hour format
        } else if (period === 'AM' && hours === 12) {
          hours = 0; // Convert 12 AM to 00
        }
      
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
      };
      

    const formRef = useRef<FormikProps<any>>(null);
    const saveInput = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <>
            <button className="btn btn-primary btn-sm" onClick={handleShow}>
                {ButtonTitle}
            </button>

            {show && (
                <div className="modal fade show d-block" id="exampleModal" role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-3" id="exampleModalLabel">{ModalTitle}</h5>
                                <button type="button" onClick={handleClose} className="btn  btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger "><span className="svg-icon svg-icon-muted svg-icon-2hx "><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px "><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="white"></rect><rect x="7" y="15.3137" width="12" height="2" rx="1" transform="rotate(-45 7 15.3137)" fill="currentColor"></rect><rect x="8.41422" y="7" width="12" height="2" rx="1" transform="rotate(45 8.41422 7)" fill="currentColor"></rect></svg></span></button>

                            </div>
                            <div className="modal-body">
                                <div className="container-fluid px-0">
                                    <div className="card">
                                        <Formik
                                            innerRef={formRef}
                                            enableReinitialize
                                            initialValues={initialValues}
                                            validationSchema={validateFun}
                                            onSubmit={async (values, { resetForm }) => {
                                                //console.log(values)
                                                setIsLoading(true);
                                                var task: any = {
                                                    ...values,
                                                    TaskId: values.TaskId === "NA" ? null : values.TaskId,
                                                    ActivityId: values.ActivityId === "NA" ? null : values.ActivityId,
                                                    startTime: convertToTimeOnly(values.startTime),
                                                    endTime: convertToTimeOnly(values.endTime),
                                                    status: 3
                                                }

                                                if (userId) {
                                                    task = {
                                                        ...values,
                                                        TaskId: values.TaskId === "NA" ? null : values.TaskId,
                                                        ActivityId: values.ActivityId === "NA" ? null : values.ActivityId,
                                                        startTime: convertToTimeOnly(values.startTime),
                                                        endTime: convertToTimeOnly(values.endTime),
                                                        status: 4,
                                                        AssignId: userId
                                                    }
                                                }

                                                addEveningReportingDispatch([task]);
                                                resetForm();
                                            }}
                                        >
                                            {({ isSubmitting, setFieldValue, values, touched }) => {
                                                if (values) {
                                                    if (values.ActivityId != 'NA' || values.TaskId != 'NA') {
                                                        if (values.TaskTypeId == '1') {
                                                            values.ActivityId = 'NA'
                                                            if (values.TaskId == 'NA') {
                                                                values.TaskId = ''
                                                            }

                                                        } else {
                                                            values.TaskId = 'NA'
                                                            if (values.ActivityId == 'NA') {
                                                                values.ActivityId = ''
                                                            }
                                                        }
                                                    }

                                                }
                                                return (
                                                    <Form noValidate className="form">
                                                     <div>
                                                     <label className="col-form-label fw-semibold fs-6 ">
                                                                        Daily Morning Task
                                                                    </label>
                                                        {dailyMorningPlannerList && dailyMorningPlannerList.loading ? (
                                                          <div className='m-6'>
                                                                <Loader/>
                                                                </div>
                                                            ):(
                                                                <div className='mb-6'>
                                                              {
                                                                dailyMorningPlanner.map(planner => (
                                                                    <label className='fs-6 d-block p-3' key={planner.Id}>
                                                                        <Field
                                                                            type="radio"
                                                                            name="toggle"
                                                                            value={planner.Id.toString()}
                                                                            checked={values.toggle === planner.Id.toString()}
                                                                            onChange={() => {
                                                                                    setFieldValue('toggle', planner.Id.toString());
                                                                                    setFieldValue('TaskTypeId', planner.TaskTypeId.toString());
                                                                                    setFieldValue('TaskId', planner.TaskId.toString());
                                                                                    setFieldValue('ActivityId', planner.ActivityId.toString());
                                                                                    setFieldValue('startTime', planner.StartTime.toString());
                                                                                    setFieldValue('endTime', planner.EndTime.toString());
                                                                            }}
                                                                        />
                                                                       &nbsp;&nbsp;{planner.TaskTypeId == '1' ? `${planner.ClientName} - ${planner.TaskName}` : `${planner.ActivityName}` } 
                                                                       &nbsp;&nbsp;<span className='fs-7 badge badge-light-primary rounded-pill'> {planner.TaskTypeName}</span>
                                                                    </label>
                                                                ))
                                                            }
                                                            </div>
                                                            )}

                                                            <div className="row mb-6 ">
                                                                <div className="col-sm-6 col-lg-6">
                                                                    <label className="col-form-label fw-semibold fs-6 required">
                                                                        Task Type
                                                                    </label>
                                                                    <div className="">
                                                                        <DropDown
                                                                            className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                 `}
                                                                            placeholder="Select Type"
                                                                            options={TaskType}
                                                                            setFieldValue={setFieldValue}
                                                                            name="TaskTypeId"
                                                                            disabled={isSubmitting}
                                                                            showSearch={true}
                                                                            currentValue={
                                                                                {
                                                                                    value: values.TaskTypeId,
                                                                                    name: TaskType.filter(x => x.value == values.TaskTypeId)[0]?.name
                                                                                }
                                                                            }
                                                                        />
                                                                        <ErrorMessage
                                                                            name="TaskTypeId"
                                                                            component="div"
                                                                            className="errorMsg"
                                                                        />
                                                                    </div>
                                                                </div>

                                                                {(workTask && values.TaskId != 'NA') && (
                                                                    <div className="col-sm-6 col-lg-6">
                                                                        <label className="col-form-label  fw-semibold fs-6 required">
                                                                            Task
                                                                        </label>
                                                                        <div className="">
                                                                            <DropDown
                                                                                className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                `}
                                                                                placeholder="Select Type"
                                                                                options={workTask}
                                                                                setFieldValue={setFieldValue}
                                                                                name="TaskId"
                                                                                disabled={isSubmitting}
                                                                                showSearch={true}
                                                                                currentValue={
                                                                                    {
                                                                                        value: values.TaskId,
                                                                                        name: workTask.filter(x => x.value == values.TaskId)[0]?.name
                                                                                    }
                                                                                }
                                                                            />
                                                                            <ErrorMessage
                                                                                name="TaskId"
                                                                                component="div"
                                                                                className="errorMsg"
                                                                            />
                                                                        </div>

                                                                    </div>

                                                                )}

                                                                

                                                                {(activities && values.ActivityId != 'NA') && (
                                                                    <div className="col-sm-6 col-lg-6">
                                                                        <label className="col-form-label fw-semibold fs-6 required">
                                                                            Activity
                                                                        </label>
                                                                        <div className="">
                                                                            <DropDown
                                                                                className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                `}
                                                                                placeholder="Select Type"
                                                                                options={activities}
                                                                                setFieldValue={setFieldValue}
                                                                                name="ActivityId"
                                                                                disabled={isSubmitting}
                                                                                showSearch={true}
                                                                                currentValue={
                                                                                    {
                                                                                        value: values.ActivityId,
                                                                                        name: activities.filter(x => x.value == values.ActivityId)[0]?.name
                                                                                    }
                                                                                }
                                                                            />
                                                                            <ErrorMessage
                                                                                name="ActivityId"
                                                                                component="div"
                                                                                className="errorMsg"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="row mb-6">
                                                                <div className="col-sm-6 col-lg-6">
                                                                    <div>
                                                                    <label className="col-form-label fw-semibold fs-6 required">
                                                                        Start Time
                                                                    </label>
                                                                    </div>
                                                                    <CustomDatePicker
                                                                        name="startTime"
                                                                        placeholder="Start Date"
                                                                        setFieldValue={setFieldValue}
                                                                        TimeDisable={false}
                                                                        TimeValue={values.startTime}
                                                                    />
                                                                </div>

                                                                <div className="col-sm-6 col-lg-6">
                                                                <div>
                                                                    <label className="col-form-label fw-semibold fs-6 required">
                                                                        End Time
                                                                    </label>
                                                                    </div>
                                                                    <CustomDatePicker
                                                                        name="endTime"
                                                                        placeholder="end Date"
                                                                        setFieldValue={setFieldValue}
                                                                        TimeDisable={false}
                                                                        TimeValue={values.endTime}
                                                                    />
                                                                    <ErrorMessage
                                                                        name="endTime"
                                                                        component="div"
                                                                        className="errorMsg"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6 col-lg-8 mt-6">
                                                                    <label className="col-form-label fw-semibold fs-6">
                                                                        Description
                                                                    </label>
                                                                    <div className="">
                                                                        <textarea
                                                                            className={`form-control form-control-lg form-control-solid ${touched.discription
                                                                                }`}
                                                                            placeholder="Description"
                                                                            id="description"
                                                                            name="description"
                                                                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                                                                setFieldValue("discription", e.target.value)
                                                                            }
                                                                            value={values.discription}
                                                                            disabled={isSubmitting}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light-primary font-weight-bold" onClick={handleClose}>Close</button>
                                <button
                                disabled={addEveningReporting.loading}
                                type="submit" className="btn btn-primary font-weight-bold" onClick={() => saveInput()}>
                                    {(addEveningReporting && addEveningReporting.loading)?(
                                   <>Adding... <span className="spinner-border spinner-border-sm align-middle ms-2"></span></>                 
                                    ):(
                                        <>Add Task</>
                                    )}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};


const mapStateToProps = (state: any) => {
    return {
        plannerActivity: state.plannerActivity,
        plannerTask: state.plannerTask,
        addEveningReporting:state.addEveningReporting,
        dailyMorningPlannerList : state.dailyMorningPlannerList
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getPlannerActivityDispatch: (searchObj: searchParams) => dispatch(getPlannerActivity(searchObj)),
        getPlannerTaskDispatch: (searchObj: searchParams) => dispatch(getPlannerTask(searchObj)),
        addEveningReportingDispatch: (data: any) => dispatch(addEveningReporting(data)),
        getEveningReportingDispatch: () => dispatch(getEveningReporting()),
        getUsersEveningReportingDispatch: (searchObj: searchParams) => dispatch(getUsersEveningReporting(searchObj)),
        getDailyMorningPlannerDispatch: (userId: string | null) => dispatch(getDailyMorningPlanner(userId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEveningReporting);


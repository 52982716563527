import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { object, string, number, array } from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { addClientValue, clientContactPerson } from "../ClientForm";
import { absolutePath } from "../../../../helpers/relativePath";
import { adminHomePage } from "../../main/admin/AdminMain";
import AddClientContactPerson from "./AddClientContactPerson";
import { CLIENT_LIST } from "../../../../helpers/routes";
import { clientListPage } from "../ClientList";
import CustomDropdown, {
  workCategoryIdDataObject,
} from "../../task/component/CustomDropdown";
import DropDown from "../../../commonComponent/dropDown/DropDown";
import { emptyObject } from "../../../../helpers/helperFunction";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";
import SelectedTaskList from "../../task/component/SelectedTaskList";
import ShowGroupContactPerson from "./ShowGroupContactPerson";
import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { useSendDataChangeWarning } from "app/helpers/webSocketHelper";
import moment from "moment";
import { PHONE_NUMBER_REGX, STATES } from "app/helpers/constants";

interface contactFromGroup {
  CompanyId: number;
  Email: string;
  GroupId: number;
  Id: number;
  IsDefault: boolean;
  Name: string;
  PhoneNumber: string;
}
interface props {
  initialValue: addClientValue;
  clientDataDispatch: Function;
  title: string;
  isUpdate: string | null | undefined;
  groupDetails: any;
  clientDetails?: any;
  newGroupContactPersonObject: clientContactPerson;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
  legalStatusList: any;
}

const validateFun = object().shape({
  name: string().trim().required("Client name is required"),
  gstin: string().max(15, "GSTIN must be at most 15 characters"),
  Legal_Status: number().min(1, "Legal Status is required"),
  panNumber: string().trim().required("Pan Number is required"),
  // groupId: number(),
  // .positive("Group name is required"),
  // .required("Group name is required"),
  clientContacts: array().of(
    object().shape({
      contactPerson: object().shape({
        name: string().required("Name is required"),
        // email: string()
        //   .email("Invalid email format")
        //   .required("Email is required"),
        phoneNumber: string()
          .matches(PHONE_NUMBER_REGX, "Phone number is not valid")
          .required("Phone number is required")
          .typeError("Phone number is required"),
      }),
    })
  ),
  Address: string().trim().required("Address is required"),
  State: string().trim().required("State is required"),
});

const accountBreadCrumbs: Array<PageLink> = [
  adminHomePage,
  clientListPage,
  emptyObject,
];

const getGroupContactPerson = (groupDetails: any, groupId: number) => {
  if (groupId === null) return [];
  const contactArr = groupDetails?.filter(
    (group: { Id: number; IsDefault: boolean }) =>
      String(group.Id) === String(groupId)
  )[0];
  if (!contactArr) return [];
  return contactArr?.Contacts?.reduce(
    (acc: Array<string>, contact: contactFromGroup) => {
      if (contact.IsDefault) {
        const updateContact = {
          companyId: contact.CompanyId,
          email: contact.Email,
          groupId: contact.GroupId,
          id: contact.Id,
          isDefault: contact.IsDefault,
          name: contact.Name,
          phoneNumber: contact.PhoneNumber,
        };
        return [...acc, { contactPerson: { ...updateContact } }];
      }
      return acc;
    },
    []
  );
};

const AddClient: FC<props> = ({
  newGroupContactPersonObject,
  clientDetails,
  clientDataDispatch,
  initialValue,
  title,
  isUpdate,
  groupDetails,
  masterDataList,
  legalStatusList,
}) => {
  console.log(initialValue, "INITIAL VALUES");
  const params = useParams();
  const navigate = useNavigate();
  const [groupAllContacts, setGroupAllContacts] =
    useState<Array<clientContactPerson>>();
  const sendDataChangeWarning = useSendDataChangeWarning();

  useEffect(() => {
    if (params.id) {
      const contactPersonData = getGroupContactPerson(
        groupDetails,
        initialValue.groupId ?? 0
      );
      setGroupAllContacts(contactPersonData);
    }
  }, [params.id]);

  const onChangeHandler = async (id: number, setFieldValue: any) => {
    const contactPersonData = getGroupContactPerson(groupDetails, id);
    setFieldValue("groupContacts", contactPersonData);
    setGroupAllContacts(contactPersonData);
  };

  const [legalStatus, SetLegalStatus] = useState<any[]>([]);

  useEffect(() => {
    if (legalStatusList && legalStatusList.data) {
      SetLegalStatus(
        legalStatusList.data.records.map((status: any) => ({
          value: status.Id,
          name: status.Name,
        }))
      );
    }
  }, [legalStatusList]);

  return (
    <div className="container-fluid px-0 client-form-container">
      <PageTitle breadcrumbs={accountBreadCrumbs}>{title}</PageTitle>
      <div className="card">
        <Formik
          enableReinitialize
          initialValues={initialValue}
          validationSchema={validateFun}
          onSubmit={async (values, { resetForm }) => {
            const updatedValues = {
              ...values,
              clientContacts: [
                ...values.clientContacts,
                ...values.groupContacts?.filter(
                  (group: clientContactPerson) => group.contactPerson?.isDefault
                ),
              ],
              subTasks: values.workCategoryId.reduce(
                (acc: any, workCategory: workCategoryIdDataObject) => [
                  ...acc,
                  ...workCategory.subKey.map((seletcedTask: any) => ({
                    ...seletcedTask,
                    fixedDate: seletcedTask.fixedDate
                      ? moment(seletcedTask.fixedDate).format("YYYY/MM/DD")
                      : seletcedTask.fixedDate,
                  })),
                ],
                []
              ),
            };
            const { groupContacts, workCategoryId, ...rest } = updatedValues;
            // console.log(rest);
            // console.log(updatedValues);

            await clientDataDispatch(
              rest,
              isUpdate
                ? () => {
                    navigate(absolutePath(CLIENT_LIST));
                    sendDataChangeWarning();
                  }
                : () => {
                    resetForm();
                    setGroupAllContacts([]);
                    sendDataChangeWarning();
                  }
            );
          }}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => {
            return (
              <Form noValidate className="form">
                <div className="p-7 mb-6">
                  <div className="d-flex flex-column gap-5 gap-md-7 mb-6">
                    <div className="fs-3 fw-semibold mb-n2">Client Details</div>
                  </div>

                  <div className="row mb-6">
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Client Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.name &&
                              errors.name &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="Client Name"
                          id="name"
                          name="name"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        Group Name
                      </label>

                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select Group Name"
                          options={masterDataList.masterData?.data?.records?.Groups?.map(
                            (group) => ({
                              value: group.Id,
                              name: group.Name,
                            })
                          )}
                          displayLoader={masterDataList.loading}
                          setFieldValue={setFieldValue}
                          name="groupId"
                          apiCallDispatch={(e: any) =>
                            onChangeHandler(e.target.dataset.id, setFieldValue)
                          }
                          currentValue={{ value: values.groupId ?? "" }}
                          defaultValue={{
                            value: clientDetails ? clientDetails.GroupId : "",
                            name: clientDetails ? clientDetails.GroupName : "",
                          }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="groupId"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                    {/* GSTIN FIELD */}
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 ">
                        GSTIN
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.gstin &&
                              errors.gstin &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="GSTIN"
                          id="gstin"
                          name="gstin"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="gstin"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-6">
                    {!params.id && (
                      <div className="col-sm-6 col-lg-4">
                        <label className="col-form-label fw-semibold fs-6">
                          Task
                        </label>

                        <div className="">
                          <CustomDropdown
                            categoryDetails={
                              masterDataList.masterData?.data?.records
                                ?.Categories
                            }
                            loader={masterDataList.loading}
                          />
                          <ErrorMessage
                            name="workCategoryId"
                            component="div"
                            className="errorMsg"
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Legal Status
                      </label>
                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select`}
                          placeholder="Select Legal Status"
                          options={legalStatus}
                          displayLoader={masterDataList.loading}
                          setFieldValue={setFieldValue}
                          name="Legal_Status"
                          apiCallDispatch={(e: any) =>
                            onChangeHandler(e.target.dataset.id, setFieldValue)
                          }
                          currentValue={{
                            value: values.Legal_Status ?? "",
                            name: legalStatus.filter(
                              (x) => x.value == values.Legal_Status
                            )[0]?.name,
                          }}
                          defaultValue={{
                            value: values.Legal_Status,
                            name: legalStatus.find(
                              (x) => x.value == values.Legal_Status
                            )?.name,
                          }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="Legal_Status"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                    {/* pan number  */}
                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Pan Number
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.panNumber &&
                              errors.panNumber &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="Pan Number"
                          id="panNumber"
                          name="panNumber"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="panNumber"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>
                    {/* Firm name */}
                    <div className="col-sm-6 col-lg-4 mb-6">
                      <label className="col-form-label fw-semibold fs-6 ">
                        Firm Name
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.FirmName &&
                              errors.FirmName &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="Firm Name"
                          id="FirmName"
                          name="FirmName"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="FirmName"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                    {/* Address and state */}
                    <div className="col-sm-6 col-lg-4 mb-6">
                      <label className="col-form-label fw-semibold fs-6 required">
                        Address
                      </label>

                      <div className="">
                        <Field
                          type="text"
                          className={`form-control form-control-lg form-control-solid 
                            ${
                              touched.Address &&
                              errors.Address &&
                              "is-invalid inValidBorder"
                            }`}
                          placeholder="Address"
                          id="Address"
                          name="Address"
                          disabled={isSubmitting}
                        />
                        <ErrorMessage
                          name="Address"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                    <div className="col-sm-6 col-lg-4">
                      <label className="col-form-label fw-semibold fs-6 required">
                        State
                      </label>

                      <div className="">
                        <DropDown
                          className={`text-start form-control form-control-lg form-control-solid form-select ${
                            masterDataList.loading
                              ? "display-dropdown-loader"
                              : ""
                          }`}
                          placeholder="Select State"
                          options={STATES.map(
                            (state) => ({
                              value: state,
                              name: state,
                            })
                          )}
                          currentValue={{ value: values.State, name: values.State }}
                          setFieldValue={setFieldValue}
                          name="State"
                          // apiCallDispatch={(e: any) =>
                          //   onChangeHandler(e.target.dataset.id, setFieldValue)
                          // }
                          //apiCallDispatch={(id: number) => onChangeHandler(id, setFieldValue)}
                          // currentValue={{ value: values.groupId ?? "" }}
                          // defaultValue={{
                          //   value: clientDetails ? clientDetails.GroupId : "",
                          //   name: clientDetails ? clientDetails.GroupName : "",
                          // }}
                          disabled={isSubmitting}
                          showSearch={true}
                        />
                        <ErrorMessage
                          name="State"
                          component="div"
                          className="errorMsg"
                        />
                      </div>
                    </div>

                    {!params.id && values.workCategoryId.length ? (
                      <div className="row">
                        <SelectedTaskList taskArr={values.workCategoryId} />
                      </div>
                    ) : (
                      ""
                    )}
                    {values.clientContacts.length ||
                    values.groupContacts?.length ? (
                      <div className="fs-3 fw-semibold mb-6">
                        Contact Person
                      </div>
                    ) : (
                      ""
                    )}
                    <ShowGroupContactPerson
                      contactArr={values.groupContacts}
                      allContactsArr={groupAllContacts}
                    />
                    <AddClientContactPerson
                      isSubmitting={isSubmitting}
                      errors={errors}
                      touched={touched}
                      values={values}
                      newGroupContactPersonObject={newGroupContactPersonObject}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end py-6 px-7 gap-3">
                  <ResetButton
                    name="Cancel"
                    className="btn btn-light btn-active-light-primary"
                    onClickCallback={() => navigate(absolutePath(CLIENT_LIST))}
                  />
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    name="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddClient;

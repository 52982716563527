import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import { connect } from "react-redux";
import { searchParams } from "app/helpers/commonInterface";
import { getPlannerActivity } from "app/reducers/activity/activityAction";
import {
  getPlannerTask,
  getUrgentPlannerTask,
} from "app/reducers/task/taskAction";
import {
  addMorningPlanner,
  getMorningPlanner,
  getUsersMorningPlanner,
} from "app/reducers/morningPlanner/plannerAction";
import * as Yup from "yup";
import { convertTo12HourFormat, emptyObject } from "app/helpers/helperFunction";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import DropDown from "app/components/commonComponent/dropDown/DropDown";
import Loader from "app/components/commonComponent/loader/Loader";
import SingleAccordion from "app/components/commonComponent/SingleAccordian";
import { endsWith } from "lodash";

// Define the type for the objects in the array
interface ItemType {
  value: number;
  name: string;
}

// Define the array with the specified type
const TaskType: ItemType[] = [
  { value: 1, name: "Task" },
  { value: 2, name: "Activity" },
];

interface props {
  ButtonTitle: string;
  ModalTitle: string;
  userId?: string;
  plannerActivity: any;
  getPlannerActivityDispatch: Function;
  plannerTask: any;
  getPlannerTaskDispatch: Function;
  addMorningPlannerDispatch: Function;
  getMorningPlannerDispatch: Function;
  getUsersMorningPlannerDispatch: Function;
  addMorningPlanner: any;
  getUrgentPlannerTaskDispatch: Function;
  urgentTaskList: any;
}
const AddMorningPlanner = ({
  ButtonTitle,
  ModalTitle,
  userId,
  plannerActivity,
  getPlannerActivityDispatch,
  plannerTask,
  getPlannerTaskDispatch,
  addMorningPlannerDispatch,
  getMorningPlannerDispatch,
  getUsersMorningPlannerDispatch,
  addMorningPlanner,
  getUrgentPlannerTaskDispatch,
  urgentTaskList,
}: props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    if (userId) {
      getUsersMorningPlannerDispatch({ userId: userId });
    } else {
      getMorningPlannerDispatch();
    }
    setShow(false);
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => {
    if (userId != null) {
      getPlannerActivityDispatch({ Paginate: false, userId: userId });
      getPlannerTaskDispatch({
        Paginate: false,
        listType: "assigned",
        userId: userId,
      });
      getUrgentPlannerTaskDispatch(userId);
    } else {
      getPlannerActivityDispatch({ Paginate: false });
      getPlannerTaskDispatch({ Paginate: false, listType: "assigned" });
      getUrgentPlannerTaskDispatch(null);
    }
    setShow(true);
  };

  const [activities, setActivities] = useState<any[]>([]);
  const [workTask, setWorkTask] = useState<any[]>([]);
  const [urgentWorkTask, setUrgentWorkTask] = useState<any[]>([]);

  useEffect(() => {
    if (
      plannerActivity.plannerActivity &&
      plannerActivity.plannerActivity.data
    ) {
      //status = 3 (Completed)
      setActivities(
        plannerActivity.plannerActivity.data.records
          .filter((activity: any) => activity.Status !== 3)
          .map((activity: any) => {
            return { value: activity.Id, name: activity.Name };
          })
      );
    }
  }, [plannerActivity]);

  useEffect(() => {
    if (plannerTask.plannerTask && plannerTask.plannerTask.data) {
      //status = 5 (Completed)
      setWorkTask(
        plannerTask.plannerTask.data.records
          .filter((activity: any) => activity.Status !== 5)
          .map((activity: any) => {
            return {
              value: activity.Id,
              name: `${activity.ClientName} - ${activity.TypeName}`,
            };
          })
      );
    }
  }, [plannerTask]);

  useEffect(() => {
    if (urgentTaskList.urgentTaskList && urgentTaskList.urgentTaskList.data) {
      //status = 5 (Completed)
      setUrgentWorkTask(
        urgentTaskList.urgentTaskList.data.records
          .filter((task: any) => task.Status !== 5)
          .map((task: any) => {
            return {
              value: task.Id,
              clientName: `${task.ClientName}`,
              taskName: `${task.TypeName}`,
              isUrgent: task.IsUrgent,
              isOverdue: task.IsOverdue,
            };
          })
      );
    }
  }, [urgentTaskList]);

const validateFun = Yup.object({
    TaskTypeId: Yup.string().required("Task Type is required"),
    TaskId: Yup.string().required("Task is required"),
    ActivityId: Yup.string().required("Activity is required"),
    // endTime: Yup.string().when("startTime", (startTime, schema) => {
    //   return (
    //     startTime &&
    //     schema.test({
    //       test: function (endTime: any) {
    //         console.log(startTime, endTime, endTime > startTime, startTime == "12:00:00 PM");
    //         return startTime != "12:00:00 PM" ? endTime > startTime : true;
    //       },
    //       message: "End Time must be greater than Start Time",
    //     })
    //   );
    // }),
    endTime: Yup.string().when("startTime", (startTime, schema) => {
      return (
        startTime &&
        schema.test({
          name: "endTimeGreaterThanStartTime",
          test: function (endTime: string) {
            const parseTimeAMPM = (time: string) => {
              const [timePart, period] = time.split(" "); 
              let [hours, minutes, seconds] = timePart.split(":").map(Number);
    
              if (period === "PM" && hours !== 12) {
                hours += 12; 
              } else if (period === "AM" && hours === 12) {
                hours = 0; 
              }
    
              return hours * 3600 + minutes * 60 + (seconds || 0); 
            };
    
            try {
              const startInSeconds = parseTimeAMPM(startTime);
              const endInSeconds = parseTimeAMPM(endTime);
    
              return endInSeconds > startInSeconds;
            } catch (error) {
              console.error("Error parsing time:", error);
              return false;
            }
          },
          message: "End Time must be greater than Start Time",
        })
      );
    }),
    
  });

  const initialValues = {
    TaskTypeId: "", // default value for TaskTypeId
    TaskId: "", // default value for TaskId
    ActivityId: "", // default value for ActivityId
    startTime: "00:00:00",
    endTime: "00:00:00",
    statusId: 3, // only added
    isDailyTask: false,
  };

  // const convertToTimeOnly = (timeString: any) => {

//   const convertToTimeOnly = (timeString: any) => {
//     // Assuming the backend expects a format like 'HH:mm:ss'
//     var [hours, minutes] = timeString.split(':');
//     //hours = timeString.includes('PM') ? hours+=12 : hours;
//     hours = (timeString.includes('PM') && parseInt(hours, 10) != 12) ? parseInt(hours, 10) + 12 : parseInt(hours, 10);
     
//     return `${hours}:${minutes}:00`;
// };


const convertToTimeOnly = (timeString: string) => {
  // Assuming timeString is in 'h:mm:ss AM/PM' format
  let [timePart, period] = timeString.split(' '); // Split time and period (AM/PM)
  let [hours, minutes] = timePart.split(':').map(Number);

  if (period === 'PM' && hours !== 12) {
    hours += 12; // Convert PM hours to 24-hour format
  } else if (period === 'AM' && hours === 12) {
    hours = 0; // Convert 12 AM to 00
  }

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
};


  //   // Assuming the backend expects a format like 'HH:mm:ss'
  //   const [hours, minutes] = timeString.split(":");
  //   return `${hours}:${minutes}:00`;
  // };

  const [taskList, setTaskList] = useState<any[]>([]);
  // const handleSubmit = () => {
  //     var validateDate = taskList.map((task: any) => {
  //         return {
  //             ...task,
  //             TaskId: task.TaskId === "NA" ? null : task.TaskId,
  //             ActivityId: task.ActivityId === "NA" ? null : task.ActivityId,
  //             startTime: convertToTimeOnly(task.startTime),
  //             endTime: convertToTimeOnly(task.endTime),
  //             status: 4
  //         };
  //     })
  //     //addMorningPlannerDispatch(validateDate)
  // }
  const formRef = useRef<FormikProps<any>>(null);

  const saveInput = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <>
      <button className="btn btn-primary btn-sm" onClick={handleShow}>
        {ButtonTitle}
      </button>

      {show && (
        <div
          className="modal fade show d-block"
          id="exampleModal"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-3" id="exampleModalLabel">
                  {ModalTitle}
                </h5>

                <button
                  type="button"
                  onClick={handleClose}
                  className="btn  btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger"
                > 
                  <span className="svg-icon svg-icon-muted svg-icon-2hx ">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mh-50px "
                    >
                      <rect
                        opacity="0.3"
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="10"
                        fill="white"
                      ></rect>
                      <rect
                        x="7"
                        y="15.3137"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(-45 7 15.3137)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="8.41422"
                        y="7"
                        width="12"
                        height="2"
                        rx="1"
                        transform="rotate(45 8.41422 7)"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid px-0">
                  <div className="card">
                    <Formik
                      innerRef={formRef}
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validateFun}
                      onSubmit={async (values, { resetForm }) => {
                        console.log(values, "VALUES WHILE SUBMIT");
                        setIsLoading(true);
                        var task: any = {
                          ...values,
                          TaskId: values.TaskId === "NA" ? null : values.TaskId,
                          ActivityId:
                            values.ActivityId === "NA"
                              ? null
                              : values.ActivityId,
                          startTime: convertToTimeOnly(values.startTime),
                          endTime: convertToTimeOnly(values.endTime),
                          status: 3,
                        };

                        if (userId) {
                          task = {
                            ...values,
                            TaskId:
                              values.TaskId === "NA" ? null : values.TaskId,
                            ActivityId:
                              values.ActivityId === "NA"
                                ? null
                                : values.ActivityId,
                            startTime: convertToTimeOnly(values.startTime),
                            endTime: convertToTimeOnly(values.endTime),
                            status: 4,
                            AssignId: userId,
                          };
                        }
                        console.log(task, "TASK");
                        addMorningPlannerDispatch([task]);
                        resetForm();
                      }}
                    >
                      {({ isSubmitting, setFieldValue, values }) => {
                        var isItSubmitting = isSubmitting;
                        if (values) {
                          if (
                            values.ActivityId != "NA" ||
                            values.TaskId != "NA"
                          ) {
                            if (values.TaskTypeId == "1") {
                              values.ActivityId = "NA";
                              if (values.TaskId == "NA") {
                                values.TaskId = "";
                              }
                            } else {
                              values.TaskId = "NA";
                              if (values.ActivityId == "NA") {
                                values.ActivityId = "";
                              }
                            }
                          }
                        }
                        return (
                          <Form noValidate className="form">
                            <div className="">
                              <label className="col-form-label fw-semibold fs-6 ">
                                Urgent Task
                              </label>
                              {urgentTaskList && urgentTaskList.loading ? (
                                <div className="m-6">
                                  <Loader />
                                </div>
                              ) : (
                                <div className="mb-6">
                                  {urgentWorkTask.map((task) => (
                                    <div key={task.value}>
                                      <label
                                        className="p-3 fs-6"
                                        htmlFor={`task-${task.value}`}
                                      >
                                        <Field
                                          type="radio"
                                          name="toggle"
                                          id={`task-${task.value}`}
                                          value={task.value.toString()}
                                          checked={
                                            values.toggle ===
                                            task.value.toString()
                                          }
                                          onChange={() => {
                                            setFieldValue(
                                              "toggle",
                                              task.value.toString()
                                            );
                                            setFieldValue("TaskTypeId", "1");
                                            setFieldValue(
                                              "TaskId",
                                              task.value.toString()
                                            );
                                          }}
                                        />
                                        &nbsp;&nbsp;{task.clientName} &nbsp;-
                                        &nbsp;{task.taskName}
                                        <span>
                                          {task.isUrgent && (
                                            <span className="fs-7 badge badge-light-primary rounded-pill">
                                              {" "}
                                              Urgent
                                            </span>
                                          )}
                                          {task.isOverdue && (
                                            <span className="fs-7 badge badge-light-danger rounded-pill">
                                              {" "}
                                              Overdue
                                            </span>
                                          )}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}

                              <div className="row mb-6">
                                <div className="col-sm-6 col-lg-6">
                                  <label className="col-form-label fw-semibold fs-6 required">
                                    Task Type
                                  </label>
                                  <div className="">
                                    <DropDown
                                      className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                 `}
                                      placeholder="Select Type"
                                      options={TaskType}
                                      setFieldValue={setFieldValue}
                                      name="TaskTypeId"
                                      disabled={isSubmitting}
                                      showSearch={true}
                                      currentValue={{
                                        value: values.TaskTypeId,
                                        name: TaskType.filter(
                                          (x) => x.value == values.TaskTypeId
                                        )[0]?.name,
                                      }}
                                    />
                                    <ErrorMessage
                                      name="TaskTypeId"
                                      component="div"
                                      className="errorMsg"
                                    />
                                  </div>
                                </div>

                                {workTask && values.TaskId != "NA" && (
                                  <div className="col-sm-6 col-lg-6">
                                    <label className="col-form-label fw-semibold fs-6 required">
                                      Task
                                    </label>
                                    <div className="">
                                      <DropDown
                                        className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                `}
                                        placeholder="Select Type"
                                        options={workTask}
                                        setFieldValue={setFieldValue}
                                        name="TaskId"
                                        disabled={isSubmitting}
                                        showSearch={true}
                                        currentValue={{
                                          value: values.TaskId,
                                          name: workTask.filter(
                                            (x) => x.value == values.TaskId
                                          )[0]?.name,
                                        }}
                                      />
                                      <ErrorMessage
                                        name="TaskId"
                                        component="div"
                                        className="errorMsg"
                                      />
                                    </div>
                                  </div>
                                )}

                                {activities && values.ActivityId != "NA" && (
                                  <div className="col-sm-6 col-lg-6">
                                    <label className="col-form-label fw-semibold fs-6 required">
                                      Activity
                                    </label>
                                    <div className="">
                                      <DropDown
                                        className={`text-start form-control form-control-lg form-control-solid form-select 
                                                                                `}
                                        placeholder="Select Type"
                                        options={activities}
                                        setFieldValue={setFieldValue}
                                        name="ActivityId"
                                        disabled={isSubmitting}
                                        showSearch={true}
                                      />
                                      <ErrorMessage
                                        name="ActivityId"
                                        component="div"
                                        className="errorMsg"
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="row mb-6">
                                <div className="col-sm-6 col-lg-6">
                                  <div>
                                    <label className="col-form-label fw-semibold fs-6 required">
                                      Start Time
                                    </label>
                                  </div>
                                  <CustomDatePicker
                                    name="startTime"
                                    placeholder="Start Date"
                                    setFieldValue={setFieldValue}
                                    TimeDisable={false}
                                    TimeValue={values.startTime}
                                  />
                                </div>

                                <div className="col-sm-6 col-lg-6">
                                  <div>
                                    <label className="col-form-label fw-semibold fs-6 required">
                                      End Time
                                    </label>
                                  </div>
                                  <CustomDatePicker
                                    name="endTime"
                                    placeholder="end Date"
                                    setFieldValue={setFieldValue}
                                    TimeDisable={false}
                                    TimeValue={values.endTime}
                                  />
                                  <ErrorMessage
                                    name="endTime"
                                    component="div"
                                    className="errorMsg"
                                  />
                                </div>
                              </div>

                              <div className="d-flex flex-row align-items-center gap-3 mt-8 col-lg-4">
                                <div className="form-check form-check-custom form-check-solid">
                                  <Field
                                    className="form-check-input"
                                    type="checkbox"
                                    name="IsDailyTask"
                                    id="isDailyTask"
                                    disabled={isSubmitting}
                                  />
                                </div>
                                <div className="col-form-label fw-semibold fs-6">
                                  Daily Task
                                </div>
                              </div>
                            </div>
                            
                          </Form>
                        );
                        
                      }}

                    </Formik>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bold"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  //disabled={addMorningPlanner.loading && isItSubmitting}
                  type="submit"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => saveInput()}
                >
                  {false ? (
                    <>
                      Adding...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </>
                  ) : (
                    <>Add Task</>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    plannerActivity: state.plannerActivity,
    plannerTask: state.plannerTask,
    addMorningPlanner: state.addMorningPlanner,
    urgentTaskList: state.urgentTaskList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPlannerActivityDispatch: (searchObj: searchParams) =>
      dispatch(getPlannerActivity(searchObj)),
    getPlannerTaskDispatch: (searchObj: searchParams) =>
      dispatch(getPlannerTask(searchObj)),
    addMorningPlannerDispatch: (data: any) => dispatch(addMorningPlanner(data)),
    getMorningPlannerDispatch: () => dispatch(getMorningPlanner()),
    getUsersMorningPlannerDispatch: (searchObj: searchParams) =>
      dispatch(getUsersMorningPlanner(searchObj)),
    getUrgentPlannerTaskDispatch: (userId: string | null) =>
      dispatch(getUrgentPlannerTask(userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMorningPlanner);

import {
  ADD_PARENT_DATA_FOR_SELECTED_LIST,
  SELECT_ALL_TASK_CHECKBOX,
  SELECT_TASK_CHECKBOX,
  UNSELECT_ALL_TASK_CHECKBOX,
  UNSELECT_TASK_CHECKBOX,
} from "../actionTypes";

export interface selectedState {
  selected: Array<number>;
  isCheckedAll: boolean;
  taskListData: Array<any>;
}

interface action {
  type: string;
  payload: any;
}

const initialState: selectedState = {
  selected: [],
  isCheckedAll: false,
  taskListData: [],
};

export const taskSelectionReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case SELECT_TASK_CHECKBOX:
      return {
        ...state,
        selected: [...state.selected, action.payload],
        isCheckedAll:
          Number(state.selected.length) + 1 ===
          Number(state.taskListData.length),
      };
    case SELECT_ALL_TASK_CHECKBOX:
      return {
        ...state,
        selected: state.taskListData.map((task: any) => task.Id),
        isCheckedAll: true,
      };
    case UNSELECT_ALL_TASK_CHECKBOX:
      return {
        ...state,
        selected: [],
        isCheckedAll: false,
      };
    case UNSELECT_TASK_CHECKBOX:
      return {
        ...state,
        selected: state.selected.filter(
          (id: number) => id !== Number(action.payload)
        ),
        isCheckedAll:
          Number(state.selected.length - 1) ===
          Number(state.taskListData.length),
      };
    case ADD_PARENT_DATA_FOR_SELECTED_LIST:
      return {
        ...state,
        taskListData: [...state.taskListData, ...action.payload.data.records],
        selected: [],
        isCheckedAll: false,
        // selected : state.isCheckedAll ? action.payload.data.records.map((task : any) => task.Id) : state.selected
      };
    default:
      return state;
  }
};

import { connect } from "react-redux";
import { FC, useMemo, useState } from "react";

import Avatar from "app/components/commonComponent/Avatar";
import { convert, getColor, getImage } from "app/helpers/helperFunction";
import { downloadFile } from "app/reducers/task/taskAction";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { selctedDocumentList } from "../DocumentsList";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { clientDocument } from "app/reducers/clientDocuments/clientDocumentsReportListReducer";
import {
  selectDocument,
  unSelectDocument,
} from "app/reducers/clientDocuments/clientDocumentsAction";
interface props {
  row: clientDocument;
  downloadFileDispatch: Function;
  unSelectDocumentDispatch: Function;
  selectDocumentDispatch: Function;
  selectedDocumentDetails: selctedDocumentList;
  isReport: boolean;
}

const DocumentTableRow: FC<props> = ({
  selectedDocumentDetails,
  row,
  downloadFileDispatch,
  unSelectDocumentDispatch,
  selectDocumentDispatch,
  isReport
}) => {
  const isDocumentChecked = useMemo(
    () => selectedDocumentDetails.selected.includes(row.Id),
    [selectedDocumentDetails]
  );
  const [loading, setLoading] = useState(false);

  const selectHandler = (e: any) => {
    if (e.target.checked) {
      selectDocumentDispatch(row.Id);
    } else {
      unSelectDocumentDispatch(row.Id);
    }
  };

  return (
    <tr>
      <td className="pe-2 ps-2">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isDocumentChecked}
            onChange={selectHandler}
          />
        </div>
      </td>
      <td className="text-dark fw-bold ps-2">
        <div className="d-flex gap-3 align-items-center">
          <div className="w-35px">
            <ImageDisplay
              path={getImage(row.MimeType)}
              errorPath="/media/task/doc-1.png"
              className="img-fit-to-div"
              altText="documentType"
            />
          </div>
          <div className="fw-semibold">
            <div className="fs-6 fw-semibold text-dark text-hover-primary">
              {row.DocumentType}
            </div>
          </div>
        </div>
      </td>
      <td>{row?.DocumentTime || "-"}</td>
      <td>{row?.TaskCode || "-"}</td>
      {isReport && <td>{row?.ClientName || "-"}</td>}
      <td>{row.DateAdded ? convert(row.DateAdded) : "-"}</td>
      <td>
        {row.UploadedBy ? (
          <div className="d-flex">
            <div className="align-items-center bg-light border d-flex fs-7 fw-semibold py-1 px-2 rounded-pill text-gray-600 min-w-125px">
              <div className="w-20px me-3">
                <Avatar
                  firstName={row.UploadedBy}
                  lastName={""}
                  size={20}
                  color={getColor(row.UploadedBy).color}
                  background={getColor(row.UploadedBy).background}
                  length={1}
                  rounded={true}
                />
              </div>
              {row.UploadedBy}
            </div>
          </div>
        ) : (
          "-"
        )}
      </td>
      <td>
        <button
          type="button"
          className="btn btn-sm btn-bg-light btn-icon btn-active-light-primary"
          title="Download"
          onClick={() => {
            setLoading(true);
            downloadFileDispatch(
              { id: row.Id },
              `${row.DocumentType}.${row.MimeType}`,
              () => {
                setLoading(false);
              }
            );
          }}
        >
          {
            loading ? (
              <>
                <div className="w-20px text-gray-600">
                  <SmallLoader />
                </div>
              </>
            ) : (
              <i className="fa-solid fa-download"></i>
            )
            // <ImageDisplay
            //     path="media/task/download-arrow.png"
            //     altText="download-arrow"
            //     className="w-20px"
            //     errorPath="/media/task/download-arrow.png" />
          }
        </button>
      </td>
    </tr>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    downloadFileDispatch: (
      downloadDataObj: { id: number },
      fileName: string,
      successCallback: Function
    ) => dispatch(downloadFile(downloadDataObj, fileName, successCallback)),
    selectDocumentDispatch: (documentId: number) =>
      dispatch(selectDocument(documentId)),
    unSelectDocumentDispatch: (documentId: number) =>
      dispatch(unSelectDocument(documentId)),
  };
};

export default connect(null, mapDispatchToProps)(DocumentTableRow);

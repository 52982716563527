//dashboard
export const DASHBOARD = "/dashboard";

// export const ARTICLE_MAIN_PAGE = "/article/home"

// export const ARTICLE_ASSIGN_TASK_DETAIL_PAGE = "/article/assign/task-detail"

// export const ARTICLE_CHECKING_TASK_DETAIL_PAGE = "/article/check/task-detail"

// export const ADMIN_MAIN_PAGE = "/admin/home"

export const ASSIGNED_TASK_LIST = "assign";

export const CHECKING_TASK_LIST = "check";

export const FOLLOWUP_TASK_LIST = "followup";

export const HOME_PAGE = "/home";

export const TASK_DETAIL_PAGE_FROM_HOME = "/home/task-detail";

//morning planner
export const ADD_MORNING_PLANNER = "/add-morning-planner"
export const ADD_EVENING_REPORTING = "/add-evening-report"

export const EDIT_MORNING_PLANNER = "/edit-morning-planner"

//users
export const ADD_USER = "/users/add";
export const EDIT_USER = "/users/edit"

export const USER_LIST = "/users";

export const PROFILE_PAGE = "/profile";

export const CHANGE_PASSWORD_PAGE = "/change-password";

export const RESET_PASSWORD_PAGE = "/reset-password";

//client
export const CLIENT_LIST = "/clients";

export const ADD_CLIENT = "/clients/add";

export const EDIT_CLIENT = "/clients/edit";

export const CONTACT_PERSON_DETAIL_PAGE = "/clients/contact-person";

export const CLIENT_DOCUMENT_LIST = "/clients/documents";

export const CLIENT_REPORT_LIST = "/client-report";

export const CLIENT_REPORT_DETAILS = "/client-report/detail";

export const DOCUMENT_REPORT_LIST = "/document-report";

//category
export const CATEGORY_LIST = "/categories";

export const ADD_CATEGORY = "/categories/add";

export const EDIT_CATEGORY = "/categories/edit";

export const VIEW_CATEGORY_TYPES = "/categories/type";

export const EDIT_CATEGORY_TYPES = "/categories/type/edit";

//task type
export const TASK_TYPE_LIST = "/task-types";

export const ADD_TASK_TYPE = "/task-types/add";

export const EDIT_TASK_TYPE = "/task-types/edit";

//task
export const TASK_LIST = "/tasks";

export const ADD_TASK = "/tasks/add";

export const EDIT_TASK = "/tasks/edit";

export const TASK_DETAIL_PAGE = "/tasks/task-detail";

export const TASK_CONTACT_PERSON_DETAIL_PAGE = "/tasks/task-detail/contact";

export const TASK_REPORT_LIST = "/task-report";

export const TASK_REPORT_DETAILS = "/task-report/detail";

export const COMPLETED_TASK_REPORT_LIST = "/completed";

export const BILLING_PENDING_TASK_LIST = "/billing-pending"

//document types
export const DOCUMENT_TYPE_LIST = "/document-types";

export const ADD_DOCUMENT_TYPE = "/document-types/add";

export const EDIT_DOCUMENT_TYPE = "/document-types/edit";

//group
export const GROUP_LIST = "/groups";

export const ADD_GROUP = "/groups/add";

export const EDIT_GROUP = "/groups/edit";

export const GROUP_CONTACT_PERSON_DETAIL_PAGE = "/groups/contact-person";

//daily-task or todo
export const DAILY_TASK_LIST = "/todos";

export const ADD_DAILY_TASK = "/todos/add";

export const EDIT_DAILY_TASK = "/todos/edit";

export const VIEW_DAILY_TASK_BY_ARTICLE = "/todos/article";

//settings
export const SETTINGS = "/settings";

export const TEMPLATE = "templates";

export const TEMPLATE_REGISTER = "templates/add";

export const WHATSAPP = "whatsapp";

export const ADD_WHATSAPP_DATA = "whatsapp/add";

//firms
export const FIRMS_LIST = "/firms";

export const ADD_FIRM = "/firms/add";

export const EDIT_FIRM = "/firms/edit";

export const FIRM_CASH_BOOK_LIST = "/firms/cash-book";

// export const ADD_CASH_BOOK = "/firms/cash-book/add"

// export const EDIT_CASH_BOOK = "/firms/cash-book/edit"

export const FIRM_BANK_BOOK_LIST = "/firms/bank-book";

// export const ADD_BANK_BOOK = "/firms/bank-book/add"

// export const EDIT_BANK_BOOK = "/firms/bank-book/edit"

export const TRANSACTION_REPORT_LIST = "/transaction-report";

//expense
export const EXPENSE_LIST = "/expenses";

export const ADD_EXPENSE = "/expenses/add";

export const EDIT_EXPENSE = "/expenses/edit";

//billing
export const BILL_LIST = "/invoices";

export const BILL_REPORT_LIST = "/invoice-report";

export const BILL_REPORT_DETAILS = "/invoice-report/detail";

//proforma invoice
export const PROFORMA_INVOICE_LIST = "/proforma-invoice";

//Reimbursement
export const REIMBURSEMENT_LIST = "/ReimbursementInvoice";

//activity
export const ADD_ACTIVITY = "/activities/add";

export const EDIT_ACTIVITY = "/activities/edit";

export const ACTIVITY_LIST = "/activities";

import { connect } from "react-redux";
import { FC, useEffect, useState } from "react";

import {
    changeDailyTaskStatus,
    getDailyTask,
} from "app/reducers/dailyTask/dailyTaskAction";
import { useAuth } from "app/components/pages/auth";
import Loader from "app/components/commonComponent/loader/Loader";
import ErrorDisplay from "app/components/commonComponent/ErrorDisplay";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { stat } from "fs";
import { getChatNotificationTasks } from "app/reducers/task/taskAction";
import TaskChat from "../taskDetail/chat/TaskChat"
import { absolutePath } from "app/helpers/relativePath";
import { TASK_DETAIL_PAGE } from "app/helpers/routes";
import { useNavigate } from "react-router-dom";
import { getOpenChat } from "app/reducers/chat/chatAction";

interface props {
    websocketData: {
        chatMessage: Map<string, Array<any>>;
        dataChangeMessage: {
            companyId: number;
            Type: string;
            IsMasterDataChange: boolean;
        };
        socket: WebSocket;
    };
    getChatNotificationTasksDispatch: Function,
    chatNotificationTasks: any,
    getOpenChatDispatch:Function
}

const NotificationList: FC<props> = ({
    websocketData,
    getChatNotificationTasksDispatch,
    chatNotificationTasks,
    getOpenChatDispatch
}) => {
    const { currentUser } = useAuth();
    // console.log(currentUser);

    const chatMess = websocketData?.chatMessage;
    const chatMessageEntries = Array.from(chatMess.entries());
    const [chatNotificationTask, setChatNotificationTask] = useState<any[]>();
    const [openChat, setOpenChat] = useState(false);
    const [chatId, setChatId] = useState(0);
    
    useEffect(() => {
        getChatNotificationTasksDispatch([1, 2, 3]);

    }, [getChatNotificationTasksDispatch])


    useEffect(() => {
        if (chatNotificationTasks.chatNotificationTasks && chatNotificationTasks.chatNotificationTasks.data && chatNotificationTasks.chatNotificationTasks.data.records) {
            setChatNotificationTask(chatNotificationTasks.chatNotificationTasks.data.records)
        }
    }, [chatNotificationTasks])

    const gotoTaskDetails = (index: any) => {
        // if(chatNotificationTask){
        //     console.log(chatNotificationTask.filter(obj => obj.Id !== index))
        //     setChatNotificationTask(chatNotificationTask.filter(obj => obj.Id !== index));
        // }
        setOpenChat(true)
        setChatId(index)
        console.log(index)
    }
    const navigate = useNavigate();


    return (
        <div className="col-12 col-lg-12 col-lg-6 col-md-5 col-xl-5 col-xxl-4 mb-8">
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-dark">Chat Notifications</span>
                    </h3>
                </div>
                <div>
                    {chatNotificationTasks.loading ? (
                    <div className="p-5">
                      <Loader />
                    </div>
                    ) : (
                        <>
                            <div className="table-responsive p-5">
                                {chatNotificationTask?.length ? (
                                    <>
                                        <table className="table table-striped table-bordered table-row-dashed">
                                            <thead>
                                                <tr className="bg-light-primary">
                                                    {/* <th  className="card-title">#</th> */}
                                                    {/* <th className="fs-5 fw-bold " style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}}>Task Code</th> */}
                                                    <th className="fs-5 fw-bold  ps-4" style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>Client</th>
                                                    <th className="fs-5 fw-bold">Type</th>
                                                    <th className="fs-5 fw-bold" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>Messages</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {chatNotificationTask.map((chat, index) => (
                                                        <tr key={index} style={{ cursor: 'pointer' }}
                                                            // onClick={() => gotoTaskDetails(Number(chat.TaskId))}
                                                            onClick={() => { getOpenChatDispatch(Number(chat.TaskId))
                                                                        navigate(absolutePath(`${TASK_DETAIL_PAGE}/${chat.TaskId}`))
                                                                            }}
                                                        >
                                                            <>
                                                                {/* <td className="card-text ">T-{Number(chat[0])}</td> */}
                                                                <td className="card-text ps-4">{chat.ClientName} </td>
                                                                <td className="card-text">{chat.TaskType}</td>
                                                                <td className="card-text"> <span className="badge badge-light-warning">{chat.Message} </span></td>
                                                            </>
                                                        </tr>
                                                    ))}
                                                </>
                                            </tbody>
                                        </table>
                                    </>
                                ) : (
                                    <div className="fs-5 fw-semibold  text-center">
                                        No Chat Found
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                </div>
            </div>
            {chatId != 0 && (
                <TaskChat id={chatId.toString()} setOpenChat={setOpenChat} isDrawer={true} openChat={openChat} />
            )}

        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        websocketData: state.websocketData,
        chatNotificationTasks: state.chatNotificationTasks
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getDailyTaskDispatch: (userNumber: number) =>
            dispatch(getDailyTask(userNumber)),
        changeDailyTaskStatusDispatch: (dailyTaskId: number, callback: Function) =>
            dispatch(changeDailyTaskStatus(dailyTaskId, callback)),
        getChatNotificationTasksDispatch: (ids: number[]) => dispatch(getChatNotificationTasks(ids)),
        getOpenChatDispatch : (taskId : number) => dispatch(getOpenChat(taskId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);

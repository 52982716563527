import { connect } from 'react-redux'
import { FC, useEffect } from 'react'

import AdminFollowupTableRow from './AdminFollowupTableRow'
import { getFollowupTaskList } from '../../../../../reducers/task/taskAction'
import { GET_TASK_FOLLOW_UP } from 'app/helpers/constants'
import Table from 'app/components/commonComponent/table/Table'
import { searchParams } from '../../../../../helpers/commonInterface'
import { useGetSearchObject } from 'app/helpers/customHooks'
import hasPaymentAccess from 'app/components/pages/auth/core/paymentAccess'
import hasPermission, { actionsRole } from 'app/components/pages/auth/core/permission'

interface props {
    followUpTaskListDetails: any
    getFollowupTaskListDispatch: Function
}

const AdminFollowupTable: FC<props> = ({ followUpTaskListDetails, getFollowupTaskListDispatch }) => {
    const HEADERS = [
        {
            columnName: "Task Code",
            id: "TaskCode",
            className: "min-w-125px ps-2"
        },
        {
            columnName: "Client Name",
            id: "ClientName",
            className: "min-w-125px ps-2"
        },
        {
            columnName: "Task",
            id: "NULL",
            className: "min-w-125px"
        },
        {
            columnName: "Due Date",
            id: "DueDate",
            className: "min-w-125px"
        },
        {
            columnName: "Article",
            id: "PersonAssigned",
            className: "min-w-125px"
        },
        {
            columnName: "Managed By",
            id: "ManagerName",
            className: "min-w-125px"
        },
        {
            columnName: "Task Type",
            id: "IsRepeatative",
            className: "min-w-125px"
        },
        ...((hasPaymentAccess())
          ? [
              {
                columnName: "Billing Status",
                id: "BillingStatus",
                className: "min-w-125px",
              },
            ]
          : []),
        {
            columnName: "Work Status",
            id: "Status",
            className: "min-w-125px"
        },
        {
            columnName: "Actions",
            id: "NULL",
            className: "min-w-125px"
        },
    ]
    const {searchObj} = useGetSearchObject();

    useEffect(() => {
        getFollowupTaskListDispatch({ ...searchObj, listType: GET_TASK_FOLLOW_UP });
    }, [searchObj, getFollowupTaskListDispatch])

    return (
        <div className='position-relative'>
           
            <Table
                checkBoxColumn={{ show: false }}
                error={followUpTaskListDetails.error}
                headers={HEADERS}
                success={followUpTaskListDetails.followupTaskList?.success}
                loading={followUpTaskListDetails.loading}
                paginate={{
                    show: true, pagination: followUpTaskListDetails.followupTaskList?.pagination,
                    PageNumber: Number(searchObj.PageNumber || 1), PageSize: Number(searchObj.PageSize) || 10
                }}
                tableData={followUpTaskListDetails.followupTaskList?.data?.records}
                tableRow={(row: any, index: number) => <AdminFollowupTableRow row={row} key={`row-${index}-${row.id}`} />}
            />
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        followUpTaskListDetails: state.followupTask
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getFollowupTaskListDispatch: (searchObj: searchParams) => dispatch(getFollowupTaskList(searchObj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminFollowupTable)
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "app/helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addBankBookService,
  getBankBookService,
  getBankBookListService,
  updateBankBookService,
  updateBankBookStatusService,
} from "./bankBookService";
import { searchParams } from "app/helpers/commonInterface";
import { addBankBookData } from "app/components/pages/bankBook/BankBookForm";
import {
  ADD_RECORD_IN_BANK_BOOK_FAILURE,
  ADD_RECORD_IN_BANK_BOOK_REQUEST,
  ADD_RECORD_IN_BANK_BOOK_SUCCESS,
  GET_RECORD_IN_BANK_BOOK_FAILURE,
  GET_RECORD_IN_BANK_BOOK_REQUEST,
  GET_RECORD_IN_BANK_BOOK_SUCCESS,
  GET_RECORD_LIST_IN_BANK_BOOK_FAILURE,
  GET_RECORD_LIST_IN_BANK_BOOK_REQUEST,
  GET_RECORD_LIST_IN_BANK_BOOK_SUCCESS,
  UPDATE_BANK_BOOK_STATUS_FAILURE,
  UPDATE_BANK_BOOK_STATUS_REQUEST,
  UPDATE_BANK_BOOK_STATUS_SUCCESS,
  UPDATE_RECORD_IN_BANK_BOOK_FAILURE,
  UPDATE_RECORD_IN_BANK_BOOK_REQUEST,
  UPDATE_RECORD_IN_BANK_BOOK_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_IN_MASTER,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addBankBook = (
  data: addBankBookData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_RECORD_IN_BANK_BOOK_REQUEST));

    await addBankBookService(data).then(
      (result: any) => {
        dispatch(success(ADD_RECORD_IN_BANK_BOOK_SUCCESS, result));
        showToastMessageSuccess("Bank book added");
        successCallback();
        console.log(result.data.data.records, "BANK BOOK DATA");
        dispatch(
          updateMasterData(ADD_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.BOOKS,
            data: result.data.data.records,
          })
        );
        console.log("ADDED TO MASTER DATA");
      },
      (error: any) => {
        dispatch(
          failure(
            ADD_RECORD_IN_BANK_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getBankBookList = (searchObj: searchParams, firmId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_RECORD_LIST_IN_BANK_BOOK_REQUEST));

    getBankBookListService(searchObj, firmId).then(
      (result: any) => {
        dispatch(success(GET_RECORD_LIST_IN_BANK_BOOK_SUCCESS, result.data));
      },
      (error: any) =>
        dispatch(
          failure(
            GET_RECORD_LIST_IN_BANK_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const getBankBook = (bankBookId: number) => {
  return (dispatch: any) => {
    dispatch(request(GET_RECORD_IN_BANK_BOOK_REQUEST));

    getBankBookService(bankBookId).then(
      (result: any) =>
        dispatch(success(GET_RECORD_IN_BANK_BOOK_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(
            GET_RECORD_IN_BANK_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};
export const updateBankBook = (
  data: addBankBookData,
  successCallback: Function
) => {
  return async (dispatch: any) => {
    dispatch(request(UPDATE_RECORD_IN_BANK_BOOK_REQUEST));

    await updateBankBookService(data).then(
      (result: any) => {
        dispatch(success(UPDATE_RECORD_IN_BANK_BOOK_SUCCESS, result.data));
        showToastMessageSuccess("Bank book updated");
        successCallback();
        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.FIRMS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_RECORD_IN_BANK_BOOK_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const updateBankBookStatus = (
  bankBookId: number,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_BANK_BOOK_STATUS_REQUEST));

    updateBankBookStatusService(bankBookId).then(
      (result: any) => {
        dispatch(success(UPDATE_BANK_BOOK_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("Bank book status updated");
        successCallback && successCallback();

        dispatch(
          updateMasterData(UPDATE_DATA_IN_MASTER, {
            masterType: MASTER_TYPE.BOOKS,
            data: result.data.data.records,
          }),
          updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
            masterType: MASTER_TYPE.BOOKS,
            data: result.data.data.records,
          })
        );
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_BANK_BOOK_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

import { connect } from "react-redux";
import { FC, useEffect } from "react";

import AdminCheckingTableRow from "./AdminCheckingTableRow";
import { getCheckingTaskToUser } from "app/reducers/task/taskAction";
import { GET_TASK_CHECKING } from "app/helpers/constants";
import Table from "app/components/commonComponent/table/Table";
import { searchParams } from "app/helpers/commonInterface";
import { useGetSearchObject } from "app/helpers/customHooks";
import hasPaymentAccess from "app/components/pages/auth/core/paymentAccess";
import hasPermission, {
  actionsRole,
} from "app/components/pages/auth/core/permission";

interface props {
  checkingTaskList: any;
  getCheckingTask: Function;
}

const AdminCheckingTable: FC<props> = ({
  checkingTaskList,
  getCheckingTask,
}) => {
  const HEADERS = [
    {
      columnName: "Task Code",
      id: "TaskCode",
      className: "min-w-125px ps-2",
    },
    {
      columnName: "Client Name",
      id: "ClientName",
      className: "min-w-125px ps-2",
    },
    {
      columnName: "Task",
      id: "NULL",
      className: "min-w-125px",
    },
    {
      columnName: "Due Date",
      id: "DueDate",
      className: "min-w-125px",
    },
    {
      columnName: "Article",
      id: "PersonAssigned",
      className: "min-w-125px",
    },
    {
      columnName: "Managed By",
      id: "CreatedBy",
      className: "min-w-125px",
    },
    {
      columnName: "Task Type",
      id: "IsRepeatative",
      className: "min-w-125px",
    },
    ...(hasPaymentAccess()
      ? [
          {
            columnName: "Billing Status",
            id: "BillingStatus",
            className: "min-w-125px",
          },
        ]
      : []),
    {
      columnName: "Work Status",
      id: "Status",
      className: "min-w-125px",
    },
    {
      columnName: "Actions",
      id: "NULL",
      className: "min-w-125px",
    },
  ];

  const { searchObj } = useGetSearchObject();

  useEffect(() => {
    getCheckingTask({ ...searchObj, listType: GET_TASK_CHECKING });
  }, [searchObj, getCheckingTask]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={checkingTaskList.error}
        headers={HEADERS}
        success={checkingTaskList.checkingTask?.success}
        loading={checkingTaskList.loading}
        paginate={{
          show: true,
          pagination: checkingTaskList.checkingTask?.pagination,
          PageNumber: Number(searchObj.PageNumber || 1),
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={checkingTaskList.checkingTask?.data?.records}
        tableRow={(row: any, index: number) => (
          <AdminCheckingTableRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    checkingTaskList: state.checkingTask,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCheckingTask: (searchObj: searchParams) =>
      dispatch(getCheckingTaskToUser(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminCheckingTable);

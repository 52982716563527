import { connect } from "react-redux";
import { FC } from "react";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import { calculateDuration, convert, convertTo12HourFormat } from "app/helpers/helperFunction";
import EditEveningReporting from "../EditEveningReporting";
import DeletePlanner from "../DeletePlanner";
interface row {
    Id: Number;
    TaskName: string;
    TaskId:string,
    ActivityId:string,
    ActivityName: string;
    TasktypeName: string;
    ActionName: string;
    StatusName: string;
    StartTime: string;
    EndTime: string;
    Status: Number;
    Date: string;
    AssignId: string,
    Discription:string,
    ClientName : string

}

type Props = {
    row: row;
    viewEveningAction:any
};

const EPCustomRow: FC<Props> = ({ row,viewEveningAction }) => {
    const discription:any = {
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
      };
      
    return (
        <tr>
            {/* <td>{Number(row.Id)}</td> */}
            <td><span className="me-2 bold fs-6">{row.TaskName} {row.ActivityName}</span>
                <div className=" badge bg-primary rounded-pill">{row.TasktypeName}</div>
            </td>
            <td>{row.ClientName != undefined ? row.ClientName : '-'}</td>
            {/* <td>{row.TaskId ? `T-${row.TaskId}` : `A-${row.ActivityId}`}</td> */}
            <td> {convertTo12HourFormat(row.StartTime)}</td>
            <td>{convertTo12HourFormat(row.EndTime)}</td>
            <td>{calculateDuration(row.StartTime,row.EndTime)}</td>
            <td>{convert(row.Date)}</td>
            <td title={row.Discription} style={discription}>{row.Discription}</td>
            <td>
                <span className={`fs-7 badge ${row.Status === 4 ? "badge-light-primary" :
                        row.Status === 7 ? "badge-light-success" :
                            row.Status === 3 ? "badge-light-primary" :
                                "badge-light-default" 
                    }`}>
                    {
                        row.Status === 4 ? "Sent For Approval" :
                            row.Status === 7 ? "Approved" :
                                row.Status === 3 ? "Added" :
                                    "Default" 
                    }
                </span>
            </td>
            {(viewEveningAction && viewEveningAction.viewEveningAction) && (
            <td className="pe-5">
                <div className="d-flex gap-3">
                <EditEveningReporting id={row.Id} ButtonTitle="Edit" ModalTitle="Edit Evening Reporting" />
                <DeletePlanner id={row.Id} type={2} userId={row.AssignId} />
                </div>
            </td>
            )}
        </tr>
    );
};

const mapStateToProps = (state: any) => {
    return {
        viewEveningAction : state.viewEveningAction,
    };
  };
  
export default connect(mapStateToProps, null)(EPCustomRow);

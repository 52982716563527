import { FC } from "react";
import { calculateDuration, convertTo12HourFormat } from "app/helpers/helperFunction";
import EditMorningPlanner from "../EditMorningPlanner";
import DeletePlanner from "../DeletePlanner";
interface row {
    Id: Number;
    TaskId:string;
    ActivityId:string;
    TaskName: string;
    ActivityName: string;
    TasktypeName: string;
    ActionName: string;
    StatusName: string;
    StartTime: string;
    EndTime: string;
    Status: Number;
    Date: string;
    AssineeName: string;
    AssignId: string;
    ClientName:string
}

type Props = {
    row: row;
};

const AMPCustomRow: FC<Props> = ({ row }) => {
    return (
        <tr>
            {/* <td>{Number(row.Id)}</td> */}
            <td><span className="me-2 bold fs-6">{row.TaskName} {row.ActivityName}</span>
                <div className=" badge bg-primary rounded-pill">{row.TasktypeName}</div>
            </td>
            <td>{row.ClientName != undefined ? row.ClientName : '-'}</td>
            {/* <td>{row.TaskId ? `T-${row.TaskId}` : `A-${row.ActivityId}`}</td> */}
            <td> {convertTo12HourFormat(row.StartTime)}</td>
            <td>{convertTo12HourFormat(row.EndTime)}</td>
            <td>{calculateDuration(row.StartTime,row.EndTime)}</td>
            <td>{row.AssineeName}</td>
            <td>
                <span className={`fs-7 badge ${row.Status === 4 ? "badge-light-primary" :
                        row.Status === 7 ? "badge-light-success" :
                            row.Status === 3 ? "badge-light-primary" :
                                "badge-light-default" // default class if no condition matches
                    }`}>
                    {
                        row.Status === 4 ? "Sent For Approval" :
                            row.Status === 7 ? "Approved" :
                                row.Status === 3 ? "Added" :
                                    "Default" // default text if no condition matches
                    }
                </span>
            </td>
            <td className="pe-5">
            <div className="d-flex gap-3">
                <EditMorningPlanner userId={row.AssignId} id={row.Id} ButtonTitle="Edit" ModalTitle="Edit Morning Planner" />
                <DeletePlanner id={row.Id} type={3} userId={row.AssignId} />
                </div>
            </td>
            
        </tr>
    );
};

export default AMPCustomRow;

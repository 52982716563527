import { connect } from "react-redux";
import { FC, useEffect } from "react";

import CustomRow from "./CustomRow";
import { getUserList } from "../../../../reducers/user/userAction";
import { searchParams } from "../../../../helpers/commonInterface";
import Table from "app/components/commonComponent/table/Table";
import { userListState } from "../../../../reducers/user/userListReducer";
import { useGetSearchObject } from "app/helpers/customHooks";

const HEADERS = [
  {
    columnName: "Name",
    id: "FirstName",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Role",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Registration Date",
    id: "RegistrationDate",
    className: "min-w-125px",
  },
  {
    columnName: "Joining Date",
    id: "JoiningDate",
    className: "min-w-125px",
  },
  {
    columnName: "Default Task",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Reporting Authority",
    id: "NULL",
    className: "min-w-125px",
  },
  {
    columnName: "Work Status",
    id: "IsActive",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-75px text-end pe-5",
  },
];

interface props {
  userListDetails: userListState;
  getUserList: Function;
}

const UsersTable: FC<props> = ({ getUserList, userListDetails }) => {
  const {searchObj} = useGetSearchObject();

  useEffect(() => {
    getUserList(searchObj);
  }, [getUserList, searchObj]);

  return (
    <>
      <Table
        checkBoxColumn={{ show: false }}
        error={userListDetails.error}
        headers={HEADERS}
        success={userListDetails.userList?.success}
        loading={userListDetails.loading}
        paginate={{
          show: true,
          pagination: userListDetails.userList?.pagination,
          PageNumber: Number(searchObj.PageNumber) || 1,
          PageSize: Number(searchObj.PageSize) || 10,
        }}
        tableData={userListDetails.userList?.data?.records}
        tableRow={(row: any, index: number) => (
          <CustomRow row={row} key={`row-${index}-${row.id}`} />
        )}
      />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userListDetails: state.userList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getUserList: (searchObj: searchParams) => dispatch(getUserList(searchObj)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);

import { connect } from 'react-redux'
import { FC, useEffect } from 'react'

import AdminAssignedTableRow from './AdminAssignedTableRow'
import { getAssigneedTaskToUser } from '../../../../../reducers/task/taskAction'
import { GET_TASK_ASSIGNED } from 'app/helpers/constants'
import { searchParams } from '../../../../../helpers/commonInterface'
import Table from 'app/components/commonComponent/table/Table'
import { useGetSearchObject } from 'app/helpers/customHooks'
import hasPaymentAccess from 'app/components/pages/auth/core/paymentAccess'
import hasPermission, { actionsRole } from 'app/components/pages/auth/core/permission'

interface props {
    assignedTaskList: any
    getAssignedTask: Function
}

const AdminAssignedTable: FC<props> = ({ assignedTaskList, getAssignedTask }) => {
    const {searchObj} = useGetSearchObject();
    const HEADERS = [
        {
            columnName: "Task Code",
            id: "TaskCode",
            className: "min-w-125px ps-2"
        },
        {
            columnName: "Client Name",
            id: "ClientName",
            className: "min-w-125px ps-2"
        },
        {
            columnName: "Task",
            id: "NULL",
            className: "min-w-125px"
        },
        {
            columnName: "Due Date",
            id: "DueDate",
            className: "min-w-125px"
        },
        {
            columnName: "Article",
            id: "PersonAssigned",
            className: "min-w-125px"
        },
        {
            columnName: "Managed By",
            id: "ManagerName",
            className: "min-w-125px"
        },
        {
            columnName: "Task Type",
            id: "IsRepeatative",
            className: "min-w-125px"
        },
        ...((hasPaymentAccess())
          ? [
              {
                columnName: "Billing Status",
                id: "BillingStatus",
                className: "min-w-125px",
              },
            ]
          : []),
        {
            columnName: "Work Status",
            id: "Status",
            className: "min-w-125px"
        },
        {
            columnName: "Actions",
            id: "NULL",
            className: "min-w-125px"
        },
    ]

    useEffect(() => {
        getAssignedTask({ ...searchObj, listType: GET_TASK_ASSIGNED });
    }, [searchObj, getAssignedTask])

    return (
        <div className='position-relative'>
            <Table
                checkBoxColumn={{ show: false }}
                error={assignedTaskList.error}
                headers={HEADERS}
                success={assignedTaskList.assignedTask?.success}
                loading={assignedTaskList.loading}
                paginate={{
                    show: true, pagination: assignedTaskList.assignedTask?.pagination,
                    PageNumber: Number(searchObj.PageNumber || 1), PageSize: Number(searchObj.PageSize) || 10
                }}
                tableData={assignedTaskList.assignedTask?.data?.records}
                tableRow={(row: any, index: number) => <AdminAssignedTableRow row={row} key={`row-${index}-${row.id}`} />}
            />
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        assignedTaskList: state.assignedTask
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getAssignedTask: (searchObj: searchParams) => dispatch(getAssigneedTaskToUser(searchObj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAssignedTable)
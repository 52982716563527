import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchReviewerRequests,
  getAllRequestForDueDateChange,
  updateRequestStatus,
} from "app/reducers/task/taskAction";
import Loader from "app/components/commonComponent/loader/Loader";
import { REQUEST_STATUS } from "app/helpers/constants";
import { getAuth } from "app/components/pages/auth";
import {
  getAllRequestForChangeDueDateReducer,
  requestData,
} from "app/reducers/task/addRequestForChangeDueDateReducer";
import { KTSVG } from "_metronic/helpers";
import { SmallLoader } from "app/components/commonComponent/buttons/Buttons";
import { convert } from "app/helpers/helperFunction";
import { request } from "http";

interface Props {
  requestForChangeDueDate: any;
  fetchReviewerRequestsDispatch: Function;
  updateRequestStatusDispatch: Function;
  getAllRequestForDueDateChangeDispatch: Function;
}

const RequestList: React.FC<Props> = ({
  requestForChangeDueDate,
  fetchReviewerRequestsDispatch,
  updateRequestStatusDispatch,
  getAllRequestForDueDateChangeDispatch,
}) => {
  const handleStatusUpdate = (
    requestId: number,
    status: number,
    reviewerId: string | undefined
  ) => {
    updateRequestStatusDispatch({ requestId, status, reviewerId });
    setTimeout(() => {
      getAllRequestForDueDateChangeDispatch();
    }, 1000);
  };

  // State to track selected requests
  const [selectedRequests, setSelectedRequests] = useState<Set<number>>(
    new Set()
  );

  // Function to toggle selection of a request
  const handleCheckboxChange = (requestId: number) => {
    setSelectedRequests((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(requestId)) {
        newSelected.delete(requestId); 
      } else {
        newSelected.add(requestId); 
      }
      return newSelected;
    });
  };

  // Handle bulk approval or rejection
  const handleBulkAction = (status: number) => {
    selectedRequests.forEach((requestId) => {
      const reviewerId = getAuth()?.Id;
      updateRequestStatusDispatch({ requestId, status, reviewerId });
    });

    setSelectedRequests(new Set());

    setTimeout(() => {
      getAllRequestForDueDateChangeDispatch();
    }, 2000);
  };

  // Fetch the requests for due date change
  useEffect(() => {
    getAllRequestForDueDateChangeDispatch();
  }, [getAllRequestForDueDateChangeDispatch]);

  const user = getAuth();

  const reviewerRequests =
  requestForChangeDueDate?.data?.data?.data?.records?.filter(
    (request: requestData) =>
      request.ReviewerIds?.some((id: string) => id === user?.Id)
  );


  return (
    // <div className="col-12 col-lg-12 col-lg-6 col-md-5 col-xl-5 col-xxl-4 mb-8">
    <div className="col-12 col-lg-12 col-lg-6 col-md-5 col-xl-5 mb-8">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              Requests for Change Due Date
            </span>
          </h3>
        </div>

        <div>
          {requestForChangeDueDate?.loading ? (
            <div className="p-5">
              <Loader />
            </div>
          ) : (
            <>
              {/* Bulk Action Buttons */}
              {selectedRequests.size > 0 && (
                <div className="d-flex justify-content-start p-3">
                  <button
                    className="btn btn-primary btn-sm mx-2"
                    onClick={() => handleBulkAction(REQUEST_STATUS.Approved.id)}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => handleBulkAction(REQUEST_STATUS.Rejected.id)}
                  >
                    Reject
                  </button>
                </div>
              )}

              <div
                className="table-responsive p-3"
                style={{ maxHeight: "220px", overflowY: "auto" }}
              >
                {reviewerRequests?.length ? (
                  <table className="table table-striped table-bordered table-row-dashed">
                    <thead>
                      <tr className="bg-light-primary">
                        <th
                          className="fs-6 ps-4"
                          style={{
                            borderTopLeftRadius: "10px",
                            borderBottomLeftRadius: "10px",
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={
                              selectedRequests.size === reviewerRequests.length
                            }
                            onChange={() => {
                              if (
                                selectedRequests.size ===
                                reviewerRequests.length
                              ) {
                                setSelectedRequests(new Set()); // Deselect all
                              } else {
                                setSelectedRequests(
                                  new Set(
                                    reviewerRequests.map(
                                      (r: { Id: any }) => r.Id
                                    )
                                  )
                                ); // Select all
                              }
                            }}
                          />
                        </th>
                        <th className="fs-6 fw-bold ps-4">Requester</th>
                        <th className="fs-6 fw-bold min-w-100px">Task Code</th>
                        <th className="fs-6 fw-bold min-w-100px">Due Date</th>
                        <th className="fs-6 fw-bold min-w-100px">
                          Requested Date
                        </th>
                        <th
                          className="fs-6 fw-bold min-w-100px"
                          style={{
                            borderTopRightRadius: "10px",
                            borderBottomRightRadius: "10px",
                          }}
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewerRequests?.map(
                        (request: requestData, index: number) => (
                          <tr key={request.Id}>
                            <td className="ps-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={`request.${request.Id}.isDefault`}
                                id={`request.${request.Id}.isDefault`}
                                checked={selectedRequests.has(request.Id)}
                                onChange={() =>
                                  handleCheckboxChange(request.Id)
                                }
                              />
                            </td>
                            <td className="fs-7 ps-4">
                              {request.RequesterName}
                            </td>
                            <td className="fs-7">{request.TaskCode}</td>
                            <td className="fs-7">
                              {convert(request.OldDueDate)}
                            </td>
                            <td className="fs-7">
                              {convert(request.RequestedDueDate)}
                            </td>
                            <td>
                              <button
                                className={`btn btn-icon btn-bg-light btn-sm p-2 btn-active-light-success mx-2`}
                                onClick={() =>
                                  handleStatusUpdate(
                                    request.Id,
                                    REQUEST_STATUS.Approved.id,
                                    user?.Id
                                  )
                                }
                              >
                                <KTSVG
                                  path={`/media/icons/duotune/general/gen037.svg`}
                                  className="svg-icon-3"
                                />
                              </button>
                              <button
                                className={`btn btn-icon btn-bg-light btn-sm p-2 btn-active-light-danger`}
                                onClick={() =>
                                  handleStatusUpdate(
                                    request.Id,
                                    REQUEST_STATUS.Rejected.id,
                                    user?.Id
                                  )
                                }
                              >
                                <KTSVG
                                  path={`/media/icons/duotune/general/gen034.svg`}
                                  className="svg-icon-3"
                                />
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                ) : (
                  <div className="fs-5 fw-semibold text-center">
                    No Requests Found
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    requestForChangeDueDate: state.requestForChangeDueDate,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchReviewerRequestsDispatch: (id: any) =>
      dispatch(fetchReviewerRequests(id)),
    updateRequestStatusDispatch: (data: {
      requestId: number;
      status: number;
      reviewerId: string;
    }) => dispatch(updateRequestStatus(data)),
    getAllRequestForDueDateChangeDispatch: () =>
      dispatch(getAllRequestForDueDateChange()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestList);

// interface Props {
//   requestForChangeDueDate: any;
//   fetchReviewerRequestsDispatch: Function;
//   updateRequestStatusDispatch: Function;
//   getAllRequestForDueDateChangeDispatch: Function;
// }

// const RequestList: React.FC<Props> = ({
//   requestForChangeDueDate,
//   fetchReviewerRequestsDispatch,
//   updateRequestStatusDispatch,
//   getAllRequestForDueDateChangeDispatch,
// }) => {
//   const handleStatusUpdate = (
//     requestId: number,
//     status: number,
//     reviewerId: string | undefined
//   ) => {
//     updateRequestStatusDispatch({ requestId, status, reviewerId });
//     setTimeout(() => {
//       getAllRequestForDueDateChangeDispatch();
//     }, 1000);
//   };

//   useEffect(() => {
//     getAllRequestForDueDateChangeDispatch();
//     //fetchReviewerRequestsDispatch();
//   }, [getAllRequestForDueDateChangeDispatch]);

//   const user = getAuth();

//   const reviewerRequests =
//     requestForChangeDueDate?.data?.data?.data?.records?.filter(
//       (request: requestData) => request.ReviewerId === user?.Id
//     );

//   return (
//     <div className="col-12 col-lg-12 col-lg-6 col-md-5 col-xl-5 col-xxl-4 mb-8">
//       <div className="card">
//         <div className="card-header">
//           <h3 className="card-title align-items-start flex-column">
//             <span className="card-label fw-bold text-dark">
//               Requests for Change Due Date
//             </span>
//           </h3>
//         </div>
//         <div>
//           {requestForChangeDueDate?.loading ? (
//             <div className="p-5">
//               <Loader />
//             </div>
//           ) : (
//             <div
//               className="table-responsive p-5"
//               style={{ maxHeight: "220px", overflowY: "auto" }}
//             >
//               {reviewerRequests?.length ? (
//                 <table className="table table-striped table-bordered table-row-dashed">
//                   <thead>
//                     <tr className="bg-light-primary">
//                       <th className="fs-5 fw-bold ps-4">Requester</th>
//                       <th className="fs-5 fw-bold">Task Code</th>
//                       <th className="fs-5 fw-bold">Due Date</th>
//                       <th className="fs-5 fw-bold">Requested Date</th>
//                       <th className="fs-5 fw-bold">Actions</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {reviewerRequests?.map((request: requestData) => (
//                       <tr key={request.Id}>
//                         <td className="card-text ps-4">
//                           {request.RequesterName}
//                         </td>
//                         <td className="card-text">{`T - ${request.TaskId}`}</td>
//                         <td className="card-text">
//                           {convert(request.OldDueDate)}
//                         </td>
//                         <td className="card-text">
//                           {convert(request.RequestedDueDate)}
//                         </td>
//                         <td>
//                           <button
//                             className={`btn btn-icon btn-bg-light btn-sm p-2 btn-active-light-success mx-2`}
//                             onClick={() =>
//                               handleStatusUpdate(
//                                 request.Id,
//                                 REQUEST_STATUS.Approved.id,
//                                 user?.Id
//                               )
//                             }
//                           >
//                             <KTSVG
//                               path={`/media/icons/duotune/general/gen037.svg`}
//                               className="svg-icon-3"
//                             />
//                           </button>
//                           <button
//                             className={`btn btn-icon btn-bg-light btn-sm p-2 btn-active-light-danger`}
//                             onClick={() =>
//                               handleStatusUpdate(
//                                 request.Id,
//                                 REQUEST_STATUS.Rejected.id,
//                                 user?.Id
//                               )
//                             }
//                           >
//                             <KTSVG
//                               path={`/media/icons/duotune/general/gen034.svg`}
//                               className="svg-icon-3"
//                             />
//                           </button>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               ) : (
//                 <div className="fs-5 fw-semibold text-center">
//                   No Requests Found
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state: any) => {
//   return {
//     requestForChangeDueDate: state.requestForChangeDueDate,
//   };
// };

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     fetchReviewerRequestsDispatch: (id: any) =>
//       dispatch(fetchReviewerRequests(id)),
//     updateRequestStatusDispatch: (data: {
//       requestId: number;
//       status: number;
//       reviewerId: string;
//     }) => dispatch(updateRequestStatus(data)),
//     getAllRequestForDueDateChangeDispatch: () =>
//       dispatch(getAllRequestForDueDateChange()),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(RequestList);

import { response } from "app/helpers/commonInterface";
import { REQUEST_STATUS } from "app/helpers/constants";
import { GET_ALL_REQUEST_FAILURE, GET_ALL_REQUEST_REQUEST, GET_ALL_REQUEST_SUCCESS } from "../actionTypes";

export interface requestData {
  Id: number;
  OldDueDate: string;
  RequestedDueDate: string;
  RequesterId: string;
  RequesterName: string;
  ReviewerIds: Array<string>;
  ReviewerName: string;
  TaskId: number;
  TaskCode?: number
  status: number;
  RequestedOn: string;
  ReviewedOn: string;
}

export interface requestsState {
  loading: boolean;
  data: response<requestData[]>;
  error: string;
}

interface action {
  type: string;
  payload: any;
}

const initialState: requestsState = {
  loading: false,
  data: {},
  error: "",
};

export const getAllRequestForChangeDueDateReducer = (state = initialState, action: action) => {
  switch (action.type) {
    case GET_ALL_REQUEST_REQUEST:
      return {
        loading: true,
      };

      case GET_ALL_REQUEST_SUCCESS:
        return {
          loading: false,
          data: action.payload, 
          error: "",
        };

    case GET_ALL_REQUEST_FAILURE:
      return {
        loading: false,
        data: {},
        error: action.payload,
      };

    default:
      return state;
  }
};

import {
  ResetButton,
  SubmitButton,
} from "app/components/commonComponent/buttons/Buttons";
import CustomDatePicker from "app/components/commonComponent/datePicker/CustomDatePicker";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import { string, object } from "yup";

interface props {
  showCompletedTaskPopup: { show: boolean; apiData: any };
  updateFun: Function;
  setShowCompletedTaskPopup: Function;
}

const CompletedTaskPopup: FC<props> = ({
  showCompletedTaskPopup,
  updateFun,
  setShowCompletedTaskPopup,
}) => {
  return (
    <Modal
      show={showCompletedTaskPopup.show}
      aria-labelledby="contained-modal-otp"
      centered
      data-toggle="modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Add Reference No.</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-5 fw-semibold">
        <Formik
          enableReinitialize
          initialValues={{ referenceNumber: "" }}
          validationSchema={object().shape({
            referenceNumber: string().required("Reference No. is required"),
          })}
          onSubmit={async (values) => {
            await updateFun(showCompletedTaskPopup.apiData, null, values.referenceNumber);
          }}
        >
          {({ values, setFieldValue, touched, errors, isSubmitting }) => {
            return (
              <Form noValidate className="form">
                <label className="col-form-label fw-semibold fs-6 required">
                  Reference No.
                </label>
                <div className="col-6 fv-row">
                <div className="">
                      <Field
                        type="text"
                        className={`form-control form-control-lg form-control-solid
                        ${
                          touched.referenceNumber &&
                          errors.referenceNumber &&
                          "is-invalid inValidBorder"
                        }`}
                        placeholder="Reference no."
                        name="referenceNumber"
                      />
                      <ErrorMessage
                        name="referenceNumber"
                        component="div"
                        className="errorMsg"
                      />
                    </div>
                </div>
                <div className="d-flex gap-3 justify-content-end mt-4">
                  <ResetButton
                    className="btn btn-light btn-sm btn-active-light-primary"
                    name="Cancel"
                    onClickCallback={() =>
                      setShowCompletedTaskPopup({ show: false, apiData: "" })
                    }
                  />
                  <SubmitButton
                    className="btn btn-primary btn-sm"
                    isSubmitting={isSubmitting}
                    name="Submit"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CompletedTaskPopup;

import Table from "app/components/commonComponent/table/Table";
import { getActivityList } from "app/reducers/activity/activityAction";
import { FC, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import ActivityTableRow from "./ActivityTableRow";
import { useSearchParams } from "react-router-dom";
import { getSearchParameter } from "app/helpers/helperFunction";
import { searchParams } from "app/helpers/commonInterface";

const HEADERS = [
  {
    columnName: "Activity Name",
    id: "Name",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Description",
    id: "Description",
    className: "min-w-250px min-w-xxl-125px",
  },
  {
    columnName: "Remark",
    id: "Remark",
    className: "min-w-250px min-w-xxl-125px",
  },
  {
    columnName: "Article",
    id: "PersonAssigned",
    className: "min-w-125px",
  },
  {
    columnName: "Created By",
    id: "CreatedBy",
    className: "min-w-125px",
  },
  {
    columnName: "Created Date",
    id: "CreatedDate",
    className: "min-w-125px",
  },
  {
    columnName: "Work Status",
    id: "Status",
    className: "min-w-125px",
  },
  {
    columnName: "Actions",
    id: "NULL",
    className: "min-w-sm-125px min-w-225px text-end pe-5",
  },
];

interface activityDetails {
  Id: number;
  IsActive: boolean;
  Name: string;
  AssigneeId: string;
  PersonAssigned: string;
  CreatorId: string;
  CreatedBy: string;
  Description: string;
  Remark: string;
  CreatedDate: string;
  Status: number;
}

interface props {
  getActivityListDispatch: Function;
  activityDetails: {
    loading: boolean;
    error: string;
    activityList: {
      data: { records: activityDetails[] };
      success: boolean;
      status: number;
      message: string;
      pagination: {
        page_number: number;
        page_size: number;
        total_record_count: number;
      };
    };
  };
}

const ActivityTable: FC<props> = ({
  activityDetails,
  getActivityListDispatch,
}) => {

  const [searchParam] = useSearchParams();
  const searchObj = useMemo(
    () => getSearchParameter(searchParam),
    [searchParam]
  );

  useEffect(() => {
    getActivityListDispatch(searchObj);
  }, [searchObj]);

  return (
    <Table
      checkBoxColumn={{ show: false }}
      error={activityDetails.error}
      headers={HEADERS}
      success={activityDetails.activityList?.success}
      loading={activityDetails.loading}
      paginate={{
        show: true,
        pagination: activityDetails.activityList?.pagination,
        PageNumber: Number(searchObj.PageNumber || 1),
        PageSize: Number(searchObj.PageSize) || 10,
      }}
      tableData={activityDetails.activityList?.data?.records}
      tableRow={(row: any, index: number) => (
        <ActivityTableRow row={row} key={`row-${index}-${row.id}`} />
      )}
    />
  );
};

const mapStateToProps = (state: any) => {
  return {
    activityDetails: state.activityList,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getActivityListDispatch: (searchObj: searchParams) =>
      dispatch(getActivityList(searchObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable);

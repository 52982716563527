import { Form, Formik, FormikValues } from "formik";
import { useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import DropDown from "app/components/commonComponent/dropDown/DropDown";
import FilterButton from "app/components/commonComponent/tableFilter/FilterButton";
import { getFilterQueryString, getSearchParameter } from "app/helpers/helperFunction";
import { useOutsideAlerter } from "app/helpers/customHooks";


const TemplateRegisterFilter = () => {
    const [searchParam, setSearchParam] = useSearchParams();
    const searchObj = useMemo(() => getSearchParameter(searchParam), [searchParam]);
    const [showDropDown, setShowDropDown] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setShowDropDown);

    const resetHandler = (resetForm: any) => {
        delete searchObj.Filters
        resetForm();
        setSearchParam({ ...searchObj })
    }

    return (
        <div className='position-relative' ref={wrapperRef}>
            <FilterButton setShowDropDown={setShowDropDown} showDropDown={showDropDown} />
            <div className={`card shadow-sm w-300px w-md-325px task-filter ${showDropDown ? "" : "hidden"}`}>
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Filter</div>
                </div>
                <div className='separator border-gray-200'></div>
                <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    <Formik
                        enableReinitialize
                        initialValues={{}}
                        onSubmit={async (values: any) => {
                            setSearchParam({ ...searchObj, PageNumber: "1", Filters: getFilterQueryString(values) })
                        }}
                    >
                        {({ resetForm, values, isSubmitting, setFieldValue }) => {
                            return (
                                <Form noValidate className="form">
                                    <div className='mb-6'>
                                        <label className='form-label fs-6 fw-bold'>Category</label>
                                        <DropDown
                                            className="text-start form-control form-control-lg form-control-solid form-select"
                                            placeholder='Select Category'
                                            options={[
                                                { value: "AUTHENTICATION", name: "Authentication" },
                                                { value: "UTILITY", name: "Utility" }
                                            ]}
                                            setFieldValue={setFieldValue}
                                            name="category"
                                            currentValue={{ value: (values as FormikValues).category }}
                                            apiCallDispatch={(e: any) => e.stopPropagation()}
                                        />
                                    </div>
                                    <div className='mb-6'>
                                        <label className='form-label fs-6 fw-bold'>Work Status</label>
                                        <DropDown
                                            className="text-start form-control form-control-lg form-control-solid form-select"
                                            placeholder='Select Status'
                                            options={[
                                                { value: "PENDING", name: "Pending" },
                                                { value: "APPROVED", name: "Approved" },
                                                { value: "REJECTED", name: "Rejected" }
                                            ]}
                                            setFieldValue={setFieldValue}
                                            name="status"
                                            currentValue={{ value: (values as FormikValues).status }}
                                            apiCallDispatch={(e: any) => e.stopPropagation()}
                                        />
                                    </div>
                                    <div className='d-flex justify-content-end gap-3'>
                                        <button
                                            type="button"
                                            className="btn btn-light btn-active-light-primary btn-sm"
                                            onClick={() => { resetHandler(resetForm); setShowDropDown(false) }}
                                        >
                                            Reset
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-sm"
                                            disabled={isSubmitting}
                                            onClick={() => setShowDropDown(false)}
                                        >
                                            {!isSubmitting && <div>Submit</div>}
                                            {isSubmitting && (
                                                <span className="indicator-progress" style={{ display: "block" }}>
                                                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            )
                        }}</Formik>
                </div>
            </div>
        </div>
    )
}

export default TemplateRegisterFilter
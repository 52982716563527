import { connect } from "react-redux";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import AddTask from "./component/AddTask";
import {
  addTask,
  getTask,
  requestDueDateChange,
  updateTask,
} from "../../../reducers/task/taskAction";
import ErrorDisplay from "../../commonComponent/ErrorDisplay";
import EditTask from "./component/EditTask";
import ErrorBoundary from "app/helpers/ErrorBoundary";
import Loader from "../../commonComponent/loader/Loader";
import { masterDataModal } from "app/reducers/masterData/masterDataReducer";
import { workCategoryIdDataObject } from "./component/CustomDropdown";
import { taskData } from "app/reducers/task/taskDataInterface";
import { taskDataState } from "app/reducers/task/getTaskByIdReducer";
import { REQUEST_STATUS } from "app/helpers/constants";

interface props {
  addTaskDispatch: Function;
  getTaskData: Function;
  updateTaskDispatch: Function;
  dueDateChangeRequestDispatch: Function;
  taskDetails: taskDataState;
  masterDataList: {
    loading: boolean;
    masterData: masterDataModal;
    error: string;
  };
}

export interface addTaskValue {
  id?: number;
  clientId: number;
  workCategoryId: Array<workCategoryIdDataObject>;
  assigneeId?: string;
  creatorId?: string;
  typeName?: string;
  typeId?: string;
  createrName?: string;
}

export interface taskEditValue {
  id: number;
  clientId: number;
  typeName: string;
  typeId: number;
  assigneeId: string;
  creatorId: string;
  createrName: string;
  fees: number;
  managerId: string;
  expertId: string;
  isRepeatative: boolean;
  fixedDate: string | undefined;
  idealDueDate: string | undefined;
  taskCode: string;
  isStopped: boolean | undefined;
}

export interface dueDateChangeValue {
  OldDueDate: string | undefined,
  RequesterId: string | undefined,
  ReviewerIds: Array<number>,
  TaskId: number,
  RequestedDueDate: string | undefined,
  TaskCode: string | undefined,
}

const initialValue: addTaskValue = {
  clientId: 0,
  workCategoryId: [],
};

const getValueForEdit = (taskData: taskData | undefined): taskEditValue => {
  return {
    id: taskData?.Id || 0,
    clientId: taskData?.ClientId || 0,
    typeName: taskData?.TypeName || "",
    typeId: taskData?.TypeId || 0,
    assigneeId: taskData?.AssigneeId || "",
    creatorId: taskData?.CreatorId || "",
    createrName: taskData?.CreatedBy || "",
    fees: taskData?.Fees || 0,
    managerId: taskData?.ManagerId || "",
    expertId: taskData?.ExpertId || "",
    idealDueDate: taskData?.IdealDueDate || undefined,
    fixedDate: taskData?.GovernmentDueDate || undefined,
    isRepeatative: taskData?.IsRepeatative || false,
    taskCode: taskData?.TaskCode || "",
    isStopped: taskData?.IsStopped || false,
  };
};

const TaskForm: FC<props> = ({
  masterDataList,
  getTaskData,
  updateTaskDispatch,
  dueDateChangeRequestDispatch,
  addTaskDispatch,
  taskDetails,
}) => {
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      getTaskData(params.id);
    }
  }, [getTaskData, params.id]);

  return (
    <ErrorBoundary>
      {params.id && taskDetails.loading && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {params.id && taskDetails.error && (
        <ErrorDisplay error={taskDetails.error} />
      )}
      {(params.id ? taskDetails.task?.success : true) && (
        <>
          {params.id && (
            <EditTask
              initialValue={getValueForEdit(taskDetails.task.data?.records)}
              taskDispatch={updateTaskDispatch}
              title="Edit Task"
              taskDetails={taskDetails.task.data?.records}
              masterDataList={masterDataList}
              dueDateChangeRequestDispatch={dueDateChangeRequestDispatch}
            />
          )}
          {!params.id && (
            <AddTask
              initialValue={initialValue}
              taskDispatch={addTaskDispatch}
              title="Add Task"
              masterDataList={masterDataList}
            />
          )}
        </>
      )}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: any) => {

  return {
    taskDetails: state.task,
    masterDataList: state.masterData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTaskDispatch: (data: addTaskValue, successCallback: Function) =>
      dispatch(addTask(data, successCallback)),
    updateTaskDispatch: (data: addTaskValue, successCallback: Function) =>
      dispatch(updateTask(data, successCallback)),
    dueDateChangeRequestDispatch: (data: dueDateChangeValue) =>
      dispatch(requestDueDateChange(data)),
    getTaskData: (taskId: number) => dispatch(getTask(taskId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskForm);
